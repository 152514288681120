
import { Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import ForgotPass from './components/login/forgotPass';
import Login from './components/login/Login';
import Dashboard from './components/dashboard/dashboard';
import Profile from './components/Profile/Profile'
import User from './components/users/User';
import About from './components/About';
import TermsCondition from './components/TermsCondition';
import PrivacyPolicy from './components/PrivacyPolicy';
import Center from './components/Center/Center';
import List from './components/SubscriptionPlan/List';
import AddPlan from './components/SubscriptionPlan/AddPlan';
import EditPlan from './components/SubscriptionPlan/EditPlan';
import CenterDetails from './components/Nurse/CenterDetails';
import PendingDetails from './components/Center/PendingDetails';
import NurseList from './components/Nurse/NurseList';
import ContactUs from './components/ContactUs/ContactUs';
import ViewContact from './components/ContactUs/ViewContact';
import ViewPatient from './components/ContactUs/ViewPatient';
import ArchiveList from './components/Archive/ArchiveList';
import DetailsNurseArchiv from './components/Archive/DetailsNurseArchiv';
import DetailsArchivePatient from './components/Archive/DetailsArchivePatient';

function App() {
  return (
    <>
      <Routes>
        <Route path='/contigo-u-admin' exact element={<Login />} />
        <Route path='/forgot-password' exact element={<ForgotPass />} />
        <Route path="/admin-profile" exact element={<Profile />} />

        <Route path='/patient-list' exact element={<User />} />


        {/* center */}
        <Route path='/center-management' exact element={<Center />} />
        <Route path='/pending-center-details' exact element={<PendingDetails />} />

        <Route path="/dashboard" exact element={<Dashboard />} />

        {/* Nurse */}
        <Route path='/nurse-list' exact element={<NurseList />} />
        <Route path='/nurse-details' exact element={<CenterDetails />} />

        {/* subscription */}
        <Route path='/subscription-plans' exact element={<List />} />
        <Route path='/add-subscription-plans' exact element={<AddPlan />} />
        <Route path='/edit-subscription-plans' exact element={<EditPlan />} />

        {/* Contact us */}
        <Route path='/contact-us-list' exact element={<ContactUs />} />
        <Route path='/contact-us-nursing-details' exact element={<ViewContact />} />
        <Route path='/contact-us-patient-details' exact element={<ViewPatient />} />

        <Route path='/archive-list' exact element={<ArchiveList />} />
        <Route path='/archive-nursing-details' exact element={<DetailsNurseArchiv />} />
        <Route path='/archive-patient-details' exact element={<DetailsArchivePatient />} />

        <Route path='/about-us' exact element={<About />} />
        <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/terms-and-condition' exact element={<TermsCondition />} />

        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
    </>
  );
}

export default App;
