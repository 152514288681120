import React, { useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CONTIGO_API_URL } from '../../BaseURL';
import { Dropdown, Table } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment/moment';
import Loader from '../include/Loader';
import Menu from '../include/Menu';
import Footer from '../include/Footer';
import { Pagination, Search } from '../Table';
import Spinner from '../include/Spinner';
import Swal from "sweetalert2";


const ContactUs = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [entity, setEntity] = useState({ nurse: [], patient: [] });
    const [totalItems, setTotalItems] = useState({ nurse: 0, patient: 0 });
    const [currentPage, setCurrentPage] = useState({ nurse: 1, patient: 1 });
    const [search, setSearch] = useState('');
    const [sorting, setSorting] = useState({ field: '', order: '' });
    const [sortingOption, setSortingOption] = useState('all');
    const [limit, setlimit] = useState(10);
    const [disable, setDisable] = useState(true);
    const [status, setStatus] = useState(1);


    useEffect(() => {
        if (location?.state?.active) {
            setStatus(location?.state?.active)
        }

        if (localStorage.getItem('ContigoU_Admin_ID') == null) {
            navigate('/contigo-u-admin');
            return;
        }
        document.getElementById('page-loader').style.display = 'none';
        var element = document.getElementById('page-container');
        element.classList.add('show');
        if (disable == true) {
            setEntity({ nurse: [], patient: [] });
            getContactUs();
        }
    }, []);


    // useEffect(() => {
    //     // Fetch data and update entity here
    //     getContactUs(); // You might need to adjust this based on your API call
    // }, [status, disable]);


    const coursesData = useMemo(() => {
        let computedPlan = status === 1 ? entity?.nurse : entity?.patient;
        if (search) {
            computedPlan = computedPlan.filter(
                (course) =>
                    course?.name?.toLowerCase()?.includes(search?.toLowerCase()) ||
                    moment(course?.createdAt)
                        .format('MM/DD/YYYY hh:mm:ss A')
                        ?.includes(search?.toLowerCase()) ||
                    course?.email?.toLowerCase()?.includes(search?.toLowerCase()) ||
                    course?.message?.toLowerCase()?.includes(search?.toLowerCase()) ||
                    course?.nursing_home?.toLowerCase()?.includes(search?.toLowerCase()) ||

                    course?.phone_no?.toString()?.includes(search?.toLowerCase())
            );
        }
        // setTotalItems({ ...totalItems, [status === 1 ? 'nurse' : 'patient']: computedPlan?.length })
        setTotalItems(prevTotalItems => ({ ...prevTotalItems, [status === 1 ? 'nurse' : 'patient']: computedPlan?.length }));

        //Sorting comments
        if (sortingOption === 'pending') {
            computedPlan = computedPlan.filter(course => course.review === 1);
        } else if (sortingOption === 'completed') {
            computedPlan = computedPlan.filter(course => course.review === 3);
        }

        if (sorting.field) {
            const reversed = sorting.order === 'asc' ? 1 : -1;
            computedPlan = computedPlan.sort((a, b) =>
                reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        //Current Page slice
        return computedPlan?.slice(
            (currentPage[status === 1 ? 'nurse' : 'patient'] - 1) * limit,
            (currentPage[status === 1 ? 'nurse' : 'patient'] - 1) * limit + limit
        );


        // }, [entity, currentPage[status], search, sorting, sortingOption, limit, status]);
    }, [entity, currentPage[status === 1 ? 'nurse' : 'patient'], search, sorting, sortingOption, limit, status]);


    console.log(currentPage, "current page")

    const switchStatus = (review) => {
        let el;
        switch (review) {
            // case 0:
            //     el = <span className="text-info">Unverified</span>;
            //     break;
            case 1:
                el = <span className="badge badge-pill badge-warning">Pending</span>;
                break;
            case 3:
                el = <span className="badge badge-pill badge-success">Completed</span>;
                break;
            case 4:
                el = <span className="badge badge-pill badge-info">Archive</span>;
                break;
            default:
                el = <span></span>;
                break;
        }
        return el;
    };


    const switchRead = (isRead) => {
        let el;
        switch (isRead) {
            case true:
                el = <span className="badge badge-pill badge-primary" style={{ backgroundColor: "#0d6efd", borderColor: "#0d6efd" }}>Read</span>;
                break;
            case false:
                el = <span className="badge badge-pill badge-secondary icon-wrapper-read" data-tooltip="Read">Unread</span>;
                break;
            default:
                el = <span></span>;
                break;
        }
        return el;
    };


    const manageContact = (id, name, status) => {
        console.log(status, "status")
        let swalTitle = "";
        let confirmButtonText = "";

        if (status === 3) {
            swalTitle = `Complete`;
            confirmButtonText = `Complete`;
        } else if (status === 4) {
            swalTitle = `Archive`;
            confirmButtonText = `Send to Archive`;
        }

        Swal.fire({
            title: swalTitle,
            text: `Are you sure you want to ${swalTitle.toLowerCase()} ${name}?`,
            icon: "success",
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: confirmButtonText,
            showCancelButton: true,

        }).then(async (res) => {
            if (res.isConfirmed) {
                const myurl = `${CONTIGO_API_URL}api/admin/manage-inquiry`;
                var bodyFormData = new URLSearchParams();
                bodyFormData.append("id", id);
                bodyFormData.append("review", status);
                axios({
                    method: "post",
                    url: myurl,
                    // url: 'http://localhost:5000/api/admin/approve-center',
                    data: bodyFormData,
                    headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Bearer " + localStorage.getItem('ContigoU_Admin_ID') },
                })
                    .then((response) => {
                        console?.log("@@", response?.data);

                        if (response?.data?.data?.review === 3) {
                            toast.success('Request successfully completed');
                            // setDisable(true);
                            // getContactUs();
                        } else if (response?.data?.data?.review === 4) {
                            toast.success('Request sent in archive');
                        } else {
                            toast.error(response?.data?.message);
                        }
                        setDisable(true);
                        getContactUs();

                    })
                    .catch((error) => {
                        toast.error("Something went wrong");
                        console?.log("Errors", error);
                    });
            }
        });
    };



    const [read, setRead] = useState([]);
    const [checkedItems, setCheckedItems] = useState({});

    const handleCheckboxChange = (id) => {
        setCheckedItems(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
        MarkRead(id);
    };

    const MarkRead = (id) => {
        setDisable(true)
        const myurl = `${CONTIGO_API_URL}api/admin/read-inquiry`; // Update with your API URL
        const bodyFormData = new URLSearchParams();
        bodyFormData.append("id", id);
        console.log(id, "read id")
        axios({
            method: "post",
            url: myurl,
            data: bodyFormData,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + localStorage.getItem('ContigoU_Admin_ID')
            },
        }).then((response) => {
            console.log(response.data, "@@");
            // toast.success('Read')
            if (response.data.success === true) {
                getContactUs();
                setCheckedItems('')
                setDisable(false)
            }

        }).catch((error) => {
            console.log(error, "Error");
            setDisable(false)
        });
    };



    const getContactUs = () => {
        const myurl = `${CONTIGO_API_URL}api/admin/get-inquiry`;
        const fd = new URLSearchParams();

        fd.append('auth_code', 'Contigo-U$123');

        fd.append('page', currentPage[status]);
        axios({
            method: 'post',
            url: myurl,
            data: fd,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: 'Bearer ' + localStorage.getItem('ContigoU_Admin_ID'),
            },
        })
            .then(async (response) => {
                console?.log('@@', response?.data);

                if (response?.data?.success === true) {
                    //   let data = await response?.data?.data?.map((e, i) => {
                    //     return { ...e, sr_no: i + 1 };
                    //   });

                    let data = await response?.data?.data

                    // status
                    const nurseData = data.filter((item) => item.status === 1);
                    const patientData = data.filter((item) => item.status === 2);



                    setEntity({ nurse: nurseData, patient: patientData });
                    setDisable(false);
                } else {
                    toast.error('Something went wrong');
                    setDisable(false);
                }
            })
            .catch((error) => {
                toast.error('Something went wrong');
                console?.log('Errors', error);
                setDisable(false);
            });
    };

    const NurseTable = ({ coursesData }) => {
        return (
            <Table striped bordered hover>
                <thead>
                    <tr>
                        {/* <th>Select</th> */}
                        <th>Sr No.</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Messages</th>
                        <th>Mobile Number</th>
                        <th>Nursing Home</th>
                        <th>Time</th>
                        <th>Select</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {coursesData?.map((elem, i) => (
                        <tr key={elem?._id}>
                            <>
                                {/* <td style={{ paddingLeft: "22px" }}>
                                    <input
                                        style={{
                                            width: "18px",
                                            height: "18px",
                                        }}
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={checkedItems[elem?.id] || false}
                                        onChange={() => handleCheckboxChange(elem?.id)}
                                    />
                                </td> */}
                                {/* <td>{i + 1}</td> */}
                                <td>{(currentPage[status === 1 ? 'nurse' : 'patient'] - 1) * limit + i + 1}</td>
                                <td>{elem?.name}</td>
                                <td>{elem?.email}</td>
                                {/* <td>{elem?.message}</td> */}
                                <td>{elem?.message?.length > 40 ? elem?.message?.substring(0, 40) + '...' : elem?.message}</td>
                                <td>{elem?.phone_no}</td>
                                <td>{elem?.nursing_home}</td>
                                <td>{moment(elem?.createdAt).format("MM/DD/YYYY hh:mm:ss A")}</td>
                                <td><span onClick={() => MarkRead(elem?.id)} style={{ cursor: "pointer" }}>{switchRead(elem?.mark_as_read)}</span></td>
                                <td>{switchStatus(elem?.review)}</td>
                                <td>
                                    <span className="text text-primary icon-wrapper" data-tooltip="View Details" style={{ cursor: "pointer" }} onClick={() => navigate("/contact-us-nursing-details", { state: { ...elem, status } })}>
                                        <u>
                                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="27" height="27" viewBox="0 0 48 48">
                                                <path fill="#2196f3" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"></path><path fill="#fff" d="M22 22h4v11h-4V22zM26.5 16.5c0 1.379-1.121 2.5-2.5 2.5s-2.5-1.121-2.5-2.5S22.621 14 24 14 26.5 15.121 26.5 16.5z"></path>
                                            </svg>
                                        </u>
                                    </span><br />
                                    <span className='text text-primary icon-wrapper' data-tooltip="Complete" onClick={() => manageContact(elem?.id, elem?.name, 3)} style={{ cursor: "pointer" }}>
                                        <u>
                                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="27" height="27" viewBox="0 0 48 48">
                                                <path fill="#c8e6c9" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"></path><path fill="#4caf50" d="M34.586,14.586l-13.57,13.586l-5.602-5.586l-2.828,2.828l8.434,8.414l16.395-16.414L34.586,14.586z"></path>
                                            </svg>
                                        </u>
                                    </span><br />
                                    <span className='icon-wrapper' data-tooltip="Archive" style={{ cursor: "pointer" }} onClick={() => manageContact(elem?.id, elem?.name, 4)}>
                                        <u>
                                            <svg width="27" height="27" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M20.5 7V13C20.5 16.7712 20.5 18.6569 19.3284 19.8284C18.1569 21 16.2712 21 12.5 21H11.5M3.5 7V13C3.5 16.7712 3.5 18.6569 4.67157 19.8284C5.37634 20.5332 6.3395 20.814 7.81608 20.9259" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round" />
                                                <path d="M12 3H4C3.05719 3 2.58579 3 2.29289 3.29289C2 3.58579 2 4.05719 2 5C2 5.94281 2 6.41421 2.29289 6.70711C2.58579 7 3.05719 7 4 7H20C20.9428 7 21.4142 7 21.7071 6.70711C22 6.41421 22 5.94281 22 5C22 4.05719 22 3.58579 21.7071 3.29289C21.4142 3 20.9428 3 20 3H16" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round" />
                                                <path d="M12 7L12 16M12 16L15 12.6667M12 16L9 12.6667" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </u>
                                    </span>
                                </td>
                            </>
                        </tr>
                    ))}
                </tbody>
            </Table>
        );
    };

    const PatientTable = ({ coursesData }) => {
        return (
            <Table striped bordered hover>
                <thead>
                    <tr>
                        {/* <th>Select</th> */}
                        <th>Sr No.</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Messages</th>
                        <th>Time</th>
                        <th>Select</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {coursesData?.map((e, i) => (
                        <tr key={e?._id}>
                            <>
                                {/* <td style={{ paddingLeft: "22px" }}>
                                    <input
                                        style={{
                                            width: "18px",
                                            height: "18px",
                                        }}
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={checkedItems[e?.id] || false}
                                        onChange={() => handleCheckboxChange(e?.id)}
                                    />
                                </td> */}
                                {/* <td>{i + 1}</td> */}
                                <td>{(currentPage[status === 1 ? 'nurse' : 'patient'] - 1) * limit + i + 1}</td>
                                <td>{e?.name}</td>
                                <td>{e?.email}</td>
                                {/* <td>{e?.message}</td> */}
                                <td>{e?.message?.length > 40 ? e.message.substring(0, 40) + '...' : e.message}</td>
                                <td>{moment(e?.createdAt).format("MM/DD/YYYY hh:mm:ss A")}</td>
                                <td><span onClick={() => MarkRead(e?.id)} style={{ cursor: "pointer" }}>{switchRead(e?.mark_as_read)}</span></td>
                                {/* <td>{switchRead(e?.mark_as_read)}</td> */}
                                <td>{switchStatus(e?.review)}</td>
                                <td>
                                    <span className="text text-primary icon-wrapper" data-tooltip="View Details" style={{ cursor: "pointer" }} onClick={() => navigate("/contact-us-patient-details", { state: { ...e, status } })}>
                                        <u>
                                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="27" height="27" viewBox="0 0 48 48">
                                                <path fill="#2196f3" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"></path><path fill="#fff" d="M22 22h4v11h-4V22zM26.5 16.5c0 1.379-1.121 2.5-2.5 2.5s-2.5-1.121-2.5-2.5S22.621 14 24 14 26.5 15.121 26.5 16.5z"></path>
                                            </svg>
                                        </u>
                                    </span><br />
                                    <span className='icon-wrapper' data-tooltip="Complete" style={{ cursor: "pointer" }} onClick={() => manageContact(e?.id, e?.name, 3)}>
                                        <u>
                                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="27" height="27" viewBox="0 0 48 48">
                                                <path fill="#c8e6c9" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"></path><path fill="#4caf50" d="M34.586,14.586l-13.57,13.586l-5.602-5.586l-2.828,2.828l8.434,8.414l16.395-16.414L34.586,14.586z"></path>
                                            </svg>
                                        </u>
                                    </span><br />
                                    <span className='icon-wrapper' data-tooltip="Archive" style={{ cursor: "pointer" }} onClick={() => manageContact(e?.id, e?.name, 4)}>
                                        <u>
                                            <svg width="27" height="27" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M20.5 7V13C20.5 16.7712 20.5 18.6569 19.3284 19.8284C18.1569 21 16.2712 21 12.5 21H11.5M3.5 7V13C3.5 16.7712 3.5 18.6569 4.67157 19.8284C5.37634 20.5332 6.3395 20.814 7.81608 20.9259" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round" />
                                                <path d="M12 3H4C3.05719 3 2.58579 3 2.29289 3.29289C2 3.58579 2 4.05719 2 5C2 5.94281 2 6.41421 2.29289 6.70711C2.58579 7 3.05719 7 4 7H20C20.9428 7 21.4142 7 21.7071 6.70711C22 6.41421 22 5.94281 22 5C22 4.05719 22 3.58579 21.7071 3.29289C21.4142 3 20.9428 3 20 3H16" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round" />
                                                <path d="M12 7L12 16M12 16L15 12.6667M12 16L9 12.6667" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </u>
                                    </span>

                                </td>
                            </>
                        </tr>
                    ))}
                </tbody>
            </Table>
        );
    };

    return (
        <div>
            <>
                <Loader />
                <div id="page-container" className="fade page-sidebar-fixed page-header-fixed">
                    <Menu />
                    <div id="content" className="content">
                        <ol className="breadcrumb float-xl-right">
                            <li className="breadcrumb-item basePath ">
                                <a href="/">Home</a>
                            </li>
                            <li className="breadcrumb-item active currentPath">Contact Us Management</li>
                        </ol>
                        <h1 className="page-header">Contact Us Management</h1>
                        {disable == false ? (
                            <div
                                style={{
                                    backgroundColor: 'white',
                                    padding: '20px',
                                    borderRadius: '20px',
                                    marginTop: '10px',
                                }}
                            >
                                <div className="row w-100">
                                    <div className="mb-3 col-12 text-center">
                                        <div className="row">
                                            <div className="col-xl-3 col-lg-3 col-sm-3 col-12 mb-3">
                                                <div className="ml-0">
                                                    <div className="d-flex">
                                                        <h5 className="mt-2 mr-1">Search: </h5>
                                                        <Search
                                                            onSearch={(value) => {
                                                                setSearch(value);
                                                                setCurrentPage({ ...currentPage, [status]: 1 });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-sm-4 col-12 d-flex justify-content-end mb-3">
                                                <div
                                                    style={{
                                                        color: 'black',
                                                        fontSize: '12px',
                                                        fontWeight: '300',
                                                        paddingTop: '0px',
                                                        paddingBottom: '0px',
                                                    }}
                                                    className="align-self-center"
                                                >
                                                    <h5 className="mt-2 mr-1">Total Contact Us : {entity[status === 1 ? 'nurse' : 'patient']?.length} </h5>
                                                </div>
                                            </div>
                                            <div className='mx-4 justify-content-end d-flex col'>
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="success" id="sorting-dropdown">
                                                        Sort By
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <Dropdown.Item onClick={() => setSortingOption('all')}>All</Dropdown.Item>
                                                        <Dropdown.Item onClick={() => setSortingOption('pending')}>Pending</Dropdown.Item>
                                                        <Dropdown.Item onClick={() => setSortingOption('completed')}>Completed</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>

                                            </div>
                                            <div className="col d-flex justify-content-end mb-3">
                                                <div
                                                    style={{
                                                        color: 'black',
                                                        fontSize: '12px',
                                                        fontWeight: '300',
                                                        paddingTop: '0px',
                                                        paddingBottom: '0px',
                                                    }}
                                                    className="align-self-center"
                                                >
                                                    <b>Rows per page :&nbsp;</b>
                                                </div>
                                                <div className="align-self-center">
                                                    <Dropdown>
                                                        <Dropdown.Toggle
                                                            variant="none"
                                                            id="dropdown-basic"
                                                            style={{
                                                                cursor: 'auto',
                                                                backgroundColor: 'white',
                                                                borderColor: '#d5dbe0',
                                                                paddingBottom: '3px',
                                                                paddingTop: '3px',
                                                            }}
                                                        >
                                                            {limit}&nbsp;<i className="fa fa-caret-down"></i>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            {limit !== 10 ? (
                                                                <>
                                                                    <Dropdown.Item
                                                                        onClick={() => {
                                                                            setlimit(10);
                                                                        }}
                                                                    >
                                                                        10
                                                                    </Dropdown.Item>
                                                                </>
                                                            ) : null}

                                                            {limit !== 20 ? (
                                                                <>
                                                                    <Dropdown.Item
                                                                        onClick={() => {
                                                                            setlimit(20);
                                                                        }}
                                                                    >
                                                                        20
                                                                    </Dropdown.Item>
                                                                </>
                                                            ) : null}

                                                            {limit !== 30 ? (
                                                                <>
                                                                    <Dropdown.Item
                                                                        onClick={() => {
                                                                            setlimit(30);
                                                                        }}
                                                                    >
                                                                        30
                                                                    </Dropdown.Item>
                                                                </>
                                                            ) : null}

                                                            {limit !== 50 ? (
                                                                <>
                                                                    <Dropdown.Item
                                                                        onClick={() => {
                                                                            setlimit(50);
                                                                        }}
                                                                    >
                                                                        50
                                                                    </Dropdown.Item>
                                                                </>
                                                            ) : null}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row ">
                                            <div className="col-12">
                                                <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                                    <li class="nav-item">
                                                        <a
                                                            class={`nav-link ${status === 1 ? 'active' : ''}`}
                                                            id="pills-nurse-tab"
                                                            data-toggle="pill"
                                                            href="#pills-nurse"
                                                            role="tab"
                                                            aria-controls="pills-nurse"
                                                            aria-selected={status === 1}
                                                            onClick={() => setStatus(1)}
                                                        >
                                                            Nursing
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class={`nav-link ${status === 2 ? 'active' : ''}`}
                                                            id="pills-patient-tab"
                                                            data-toggle="pill"
                                                            href="#pills-patient"
                                                            role="tab"
                                                            aria-controls="pills-patient"
                                                            aria-selected={status === 2}
                                                            onClick={() => setStatus(2)}
                                                        >
                                                            Patient
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div class="tab-content" id="pills-tabContent">
                                                    <div
                                                        class={`tab-pane fade ${status === 1 ? 'show active' : ''}`}
                                                        id="pills-nurse"
                                                        role="tabpanel"
                                                        aria-labelledby="pills-nurse-tab"
                                                    >
                                                        <NurseTable coursesData={coursesData} />
                                                    </div>
                                                    <div
                                                        class={`tab-pane fade ${status === 2 ? 'show active' : ''}`}
                                                        id="pills-patient"
                                                        role="tabpanel"
                                                        aria-labelledby="pills-patient-tab"
                                                    >
                                                        <PatientTable coursesData={coursesData} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* {status === 1 && ( */}
                                        <div
                                            className="mt-2 d-flex justify-content-sm-center justify-content-xs-center justify-content-lg-end"
                                            style={{
                                                overflowX: 'auto',
                                            }}
                                        >
                                            {console.log(currentPage, "current of bootom")}
                                            {console.log(totalItems, "total bootom")}
                                            {console.log(currentPage.patient, "patient page")}
                                            <Pagination
                                                total={totalItems[status === 1 ? 'nurse' : 'patient']}
                                                itemsPerPage={limit}
                                                currentPage={currentPage[status === 1 ? 'nurse' : 'patient']}
                                                onPageChange={(page) => setCurrentPage({ ...currentPage, [status === 1 ? 'nurse' : 'patient']: page })}
                                            />
                                        </div>
                                        {/* )} */}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <Spinner />
                        )}
                    </div>
                    <Footer />
                </div>
            </>
        </div>
    );
};

export default ContactUs;
