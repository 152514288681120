import React, { useState, useEffect } from 'react'
import Footer from '../include/Footer'
import Loader from '../include/Loader'


import ColDetail from '../general/ColDetail'
import moment from "moment/moment";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import Menu from '../include/Menu';



const ViewContact = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const data = location.state;

    useEffect(() => {
        if (localStorage.getItem("ContigoU_Admin_ID") == null) {
            navigate("/contigo-u-admin");
            return;
        }
        document.getElementById("page-loader").style.display = "none";

        var element = document.getElementById("page-container");
        element.classList.add("show");
    }, []);

    const switchStatus = (review) => {
        let el;
        switch (review) {
            // case 0:
            //     el = <span className="text-info">Unverified</span>;
            //     break;
            case 1:
                el = <span className="badge badge-pill badge-warning">Pending</span>;
                break;
            case 3:
                el = <span className="badge badge-pill badge-success">Completed</span>;
                break;
            case 4:
                el = <span className="badge badge-pill badge-info">Archive</span>;
                break;
            default:
                el = <span></span>;
                break;
        }
        return el;
    };


    const switchRead = (isRead) => {
        let el;
        switch (isRead) {
            case true:
                el = <span className="badge badge-pill badge-primary" style={{ backgroundColor: "#0d6efd", borderColor: "#0d6efd" }}>Read</span>;
                break;
            case false:
                el = <span className="badge badge-pill badge-secondary">Unread</span>;
                break;
            default:
                el = <span></span>;
                break;
        }
        return el;
    };


    return (
        <div>
            <>
                <Loader />
                <div
                    id="page-container"
                    className="fade page-sidebar-fixed page-header-fixed"
                >
                    <Menu />
                    <div id="content" className="content">
                        <ol className="breadcrumb float-xl-right">
                            <li className="breadcrumb-item basePath ">
                                {/* <a href="javascript:;">User List</a> */}
                                <Link
                                    to={{ pathname: "/contact-us-list", state: { active: location?.state?.status } }}>ContactUs List</Link>
                            </li>
                            <li className="breadcrumb-item active currentPath">
                                ContactUs Details
                            </li>
                        </ol>
                        <h1 className="page-header">
                            <i
                                className="fa fa-arrow-left edit"
                                onClick={() => navigate("/contact-us-list", { state: { active: location?.state?.status } })}
                                style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                    marginTop: "7px",
                                    marginRight: "10px",
                                }}
                            ></i>
                            ContactUs Details
                        </h1>

                        {/* <div className="btn btn-success mt-2 px-3">Verify</div> */}
                        <div
                            style={{
                                backgroundColor: "white",
                                padding: "20px",
                                borderRadius: "20px",
                                marginTop: "10px",
                            }}
                        >
                            <div className="row RestName p-5" style={{ borderRadius: "20px" }}>
                                <div className="mx-auto ">
                                    <span style={{ fontSize: "18px", fontWeight: "700" }}>
                                        Details of {data?.name}
                                    </span>
                                    {/* <i className="fa fa-edit ml-2" onClick={()=>{navigate("/edit-guru-details", {state: data,})}}></i> */}
                                </div>
                            </div>
                            <br />

                            <div>
                                <div className="row">
                                    <ColDetail title='Email' data={data?.email} />
                                    <ColDetail title='Nurshing Home' data={data?.nursing_home} />
                                    <ColDetail title='Phone Number' data={data?.phone_no} />
                                    <ColDetail title='Status' data={switchStatus(data?.review)} />
                                    <ColDetail title='Mark As Read' data={switchRead(data?.mark_as_read)} />
                                    <ColDetail title='Time' data={moment(data?.createdAt).format("MM/DD/YYYY hh:mm:ss A")} />
                                    <ColDetail title='Message' data={data?.message} />
                                </div>
                            </div>

                        </div>
                    </div>
                    {/* <Footer /> */}
                </div>
            </>
        </div>
    )
}

export default ViewContact