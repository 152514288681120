import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function Menu() {
  const navigate = useNavigate();


  useEffect(() => {
    console.log(localStorage.getItem("ContigoU_Admin_ID"))
    if (localStorage.getItem("ContigoU_Admin_ID") == null) {
      toast.error("!..Please login first");
      navigate("/contigo-u-admin");
    }
  }, []);
  const Logout = () => {
    localStorage.removeItem("ContigoU_Admin_ID");
    localStorage.removeItem("ContigoU_Admin_EMAIL");
    localStorage.removeItem("ContigoU_Admin_NAME");
    localStorage.removeItem("ContigoU_Admin_IMAGE");
    navigate("/contigo-u-admin");
    toast.success("!Logout Successfully");
  };

  var dashboardClass = window.location.pathname.match(/^\/dashboard/)
    ? "active"
    : "";

  var userListClass =
    window.location.pathname.match(/^\/patient-list/)
      // window.location.pathname.match(/^\/users-details/)
      ? "active"
      : "";

  var cmsListClass =
    window.location.pathname.match(/^\/cms-list/)
      // window.location.pathname.match(/^\/users-details/)
      ? "active"
      : "";

  var centerListClass =
    window.location.pathname.match(/^\/center-management/)
      // window.location.pathname.match(/^\/users-details/)
      ? "active"
      : "";

  var aboutListClass =
    window.location.pathname.match(/^\/about-us/)
      // window.location.pathname.match(/^\/users-details/)
      ? "active"
      : "";

  var tandcListClass =
    window.location.pathname.match(/^\/terms-and-condition/)
      // window.location.pathname.match(/^\/users-details/)
      ? "active"
      : "";

  var ppListClass =
    window.location.pathname.match(/^\/privacy-policy/)
      // window.location.pathname.match(/^\/users-details/)
      ? "active"
      : "";

  var subscriptionClass = window.location.pathname.match(/^\/subscription-plans/)
    ? "active"
    : "";

  var nurseListClass = window.location.pathname.match(/^\/nurse-list/)
    ? "active"
    : "";

    var contactClass = window.location.pathname.match(/^\/contact-us-list/)
    ? "active"
    : "";

    var archiveClass = window.location.pathname.match(/^\/archive-list/)
    ? "active"
    : "";


    // useEffect(() => {
  //   localStorage.getItem("visible")
  //     ? localStorage.setItem("visible", visible)
  //     : localStorage.setItem("visible", false);
  //   console.log(localStorage.getItem("visible"));
  // }, [visible]);

  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const toggleSubMenu = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  };

  // const cmsListClass = isSubMenuOpen ? 'active' : '';

  return (
    <>
      <div id="header" className="header navbar-default">
        <div className="navbar-header">
          <NavLink to="/dashboard" className="navbar-brand ">
            {/* <img
              src="/assets/img/logo/Vector.png"
              alt="Chef-event-logo"
              className="d-inline-block align-text-top mr-2"
            /> */}
            <span className="logo"></span>
            <span className="BrudAdmin">
              {/* <b>OM Outline</b> */}
              <img src="/assets/img/contigo.svg" alt="contigo-img" className="img-fluid" />
            </span>
          </NavLink>

          <button
            type="button"
            className="navbar-toggle"
            data-click="sidebar-toggled"
          >
            <span className="icon-bar" style={{ color: "#fff" }}></span>
            <span className="icon-bar" style={{ color: "#fff" }}></span>
            <span className="icon-bar" style={{ color: "#fff" }}></span>
          </button>
        </div>

        <ul className="navbar-nav navbar-right admin">
          <li className="dropdown navbar-user">
            <span className="dropdown-toggle" data-toggle="dropdown">
              <img src={localStorage.getItem("ContigoU_Admin_IMAGE")} alt="" />
              <span className="d-none d-md-inline userName">
                {localStorage.getItem("ContigoU_Admin_NAME")}
              </span>{" "}
              <i class="fa fa-caret-down "></i>
            </span>
            <div
              className="dropdown-menu "
              style={{ borderRadius: "00px", padding: "5px ", width: '-webkit-fill-available' }}
            >
              <NavLink
                to="/admin-profile"
                style={{ textDecoration: "none", color: "#404252" }}
              >
                <span className="dropdown-item">
                  <span className="DropdownItem" style={{ color: "#404252" }}>Edit Profile</span>
                </span>
              </NavLink>
              <span className="dropdown-item" onClick={Logout}>
                <span className="DropdownItem">
                  <span className="DropdownItem ml-0">Log Out</span>
                </span>
              </span>
            </div>
          </li>
        </ul>
      </div>

      <div id="sidebar" className="sidebar">
        <div data-scrollbar="true" data-height="100%" style={{ overflow: "auto" }}>
          <div className="nav-header"></div>
          <ul className="nav">
            <li className="sidebarList">
              <NavLink
                to="/dashboard"
                className={dashboardClass}
                activeClassName="active"
              >
                <div className="menuItemDiv">
                  <i className="fa fa-th-large menuIcon"></i>
                  <span className="menuItem">Dashboard</span>
                </div>
              </NavLink>
            </li>

            <li className="sidebarList">
              <NavLink
                to="/patient-list"
                className={userListClass}
                activeClassName="active"
              >
                <div className="menuItemDiv">
                  <i className="fa fa-users menuIcon"></i>
                  <span className="menuItem">Patient</span>
                </div>
              </NavLink>
            </li>

            <li className="sidebarList">
              <NavLink
                to="/nurse-list"
                className={nurseListClass}
                activeClassName="active"
              >
                <div className="menuItemDiv">
                  <i class="fas fa-user-nurse menuIcon"></i>
                  <span className="menuItem">Nurse</span>
                </div>
              </NavLink>
            </li>

            <li className="sidebarList">
              <NavLink
                to="/center-management"
                className={centerListClass}
                activeClassName="active"
              >
                <div className="menuItemDiv">
                  <i className="fas fa-hospital menuIcon"></i>
                  <span className="menuItem">Center Management</span>
                </div>
              </NavLink>
            </li>

            <li className="sidebarList">
              <NavLink
                to="/subscription-plans"
                className={subscriptionClass}
                activeClassName="active"
              >
                <div className="menuItemDiv">
                  <i class="fas fa-clipboard-list menuIcon"></i>
                  <span className="menuItem">Subscription Plans</span>
                </div>
              </NavLink>
            </li>

            <li className="sidebarList">
              <NavLink
                to="/contact-us-list"
                className={contactClass}
                activeClassName="active"
              >
                <div className="menuItemDiv">
                <i class="fas fa-envelope menuIcon"></i>
                  <span className="menuItem">Contact Us</span>
                </div>
              </NavLink>
            </li>

            <li className="sidebarList">
              <NavLink
                to="/archive-list"
                className={archiveClass}
                activeClassName="active"
              >
                <div className="menuItemDiv">
                <i class="fas fa-archive menuIcon"></i>
                  <span className="menuItem">Archive</span>
                </div>
              </NavLink>
            </li>

            <li className="sidebarList" onClick={toggleSubMenu}>
              <a>
                <div className="menuItem">
                  <i class="fas fa-file menuIcon"></i>
                  <span className="menuItem" style={{ color: "black" }}>CMS</span>
                  {isSubMenuOpen ? (
                    <i className="fa fa-angle-down menuIcon" style={{ color: "black", float: "right" }}></i>
                  ) : (
                    <i className="fa fa-angle-right menuIcon" style={{ color: "black", float: "right" }}></i>
                  )}
                </div>
                {isSubMenuOpen && (
                  <ul className="submenu">
                    <li>
                      <NavLink
                        to="/about-us"
                        className={aboutListClass}
                        activeClassName="active"
                      >
                        <div className="menuItemDiv">
                          <span className="menuItem">About us</span>
                        </div>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/terms-and-condition"
                        className={tandcListClass}
                        activeClassName="active"
                      >
                        <div className="menuItemDiv">
                          <span className="menuItem">T & C</span>
                        </div>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/privacy-policy"
                        className={ppListClass}
                        activeClassName="active"
                      >
                        <div className="menuItemDiv">
                          <span className="menuItem">Privacy Policy</span>
                        </div>
                      </NavLink>
                    </li>
                  </ul>
                )}
              </a>

            </li>



            {/* <li className="sidebarList">
              <NavLink
                to="/events"
                className={eventListClass}
                activeClassName="active"
              >
                <div className="menuItemDiv">
                  <EventIcon
                    className="menuIcon"
                    style={{ fontSize: '20px', marginTop: '-4px' }}
                  />
                  <span className="menuItem">Event</span>
                </div>
              </NavLink>
            </li>
            <li className="sidebarList">
              <NavLink
                to="/billing"
                className={billingClass}
                activeClassName="active"
              >
                <div className="menuItemDiv">
                  <i class="fa fa-money-bill menuIcon"></i>
                  <span className="menuItem">Billing</span>
                </div>
              </NavLink>
            </li>
            <li className="sidebarList">
              <NavLink
                to="/booking"
                className={bookingClass}
                activeClassName="active"
              >
                <div className="menuItemDiv">
                  <CollectionsBookmarkIcon
                    className="menuIcon"
                    style={{ fontSize: '20px', marginTop: '-4px' }}
                  />
                  <span className="menuItem">Booking</span>
                </div>
              </NavLink>
            </li>
            <li className="sidebarList">
              <NavLink
                to="/feedback"
                className={feedbackClass}
                activeClassName="active"
              >
                <div className="menuItemDiv">
                  <FeedbackIcon
                    className="menuIcon"
                    style={{ fontSize: '20px', marginTop: '-4px' }}
                  />
                  <span className="menuItem">Feedback</span>
                </div>
              </NavLink>
            </li>
            <li className="sidebarList">
              <NavLink
                to="/terms-and-conditions"
                className={TandC}
                activeClassName="active"
              >
                <div className="menuItemDiv">
                  <i className="menuIcon">
                    <GavelIcon
                      style={{ fontSize: '20px', marginTop: '-4px' }}
                    />
                  </i>
                  <span className="menuItem">T & C</span>
                </div>
              </NavLink>
            </li>
            <li className="sidebarList">
              <NavLink
                to="/privacy-policy"
                className={pp}
                activeClassName="active"
              >
                <div className="menuItemDiv">
                  <i className="menuIcon">
                    <PolicyIcon
                      style={{ fontSize: '20px', marginTop: '-4px' }}
                    />
                  </i>
                  <span className="menuItem">Policy</span>{' '}
                </div>
              </NavLink>
            </li>
            <li className="sidebarList">
              <NavLink
                to="/about-us"
                className={aboutus}
                activeClassName="active"
              >
                <div className="menuItemDiv">
                  <i className="menuIcon">
                    <InfoIcon style={{ fontSize: '20px', marginTop: '-4px' }} />
                  </i>
                  <span className="menuItem">AboutUs</span>
                </div>
              </NavLink>
            </li> */}
          </ul>
        </div>
      </div>
    </>
  );
}

export default Menu;
