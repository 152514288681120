import React, { useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import Loader from '../include/Loader'
import Menu from '../include/Menu'
import axios from 'axios';
import { CONTIGO_API_URL } from "../../BaseURL";
import { toast } from "react-toastify";
import Footer from '../include/Footer';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const EditPlan = () => {

    const location = useLocation();
    var data = location.state;
    console.log(data, "data")
    const navigate = useNavigate();
    const [change, setChange] = useState(true);
    const [editPlan, setEditPlan] = useState({
        plan_name: location?.state?.plan_name,
        description: location?.state?.description,
        amount: location?.state?.amount,
        amount_months: location?.state?.amount_months,
        amount_year: location?.state?.amount_year,
        type: location?.state?.type,
        _id: location?.state?.plan_id
    });
    const [errors, setErrors] = useState({});
    const [disable, setDisable] = useState(false);


    useEffect(() => {
        if (localStorage.getItem("ContigoU_Admin_ID") == null) {
            navigate("/contigo-u-admin");
            return;
        }
        document.getElementById("page-loader").style.display = "none";

        var element = document.getElementById("page-container");
        element.classList.add("show");

    }, []);

    const submitHandler = async (e) => {
        e.preventDefault();
        if (validate()) {
            setDisable(true);

            const { name } = editPlan;
            const myurl = `${CONTIGO_API_URL}api/admin/edit-subscriptionplan`;
            var bodyFormData = new URLSearchParams();
            bodyFormData.append("plan_name", editPlan.plan_name);
            bodyFormData.append("description", editPlan.description);
            // bodyFormData.append("duration", editPlan.duration);
            bodyFormData.append("amount", parseFloat(editPlan.amount).toFixed(2));
            bodyFormData.append("amount_months", parseFloat(editPlan.amount_months).toFixed(2));
            bodyFormData.append("amount_year", parseFloat(editPlan.amount_year).toFixed(2));
            //         bodyFormData.append("type", editPlan.type);
            bodyFormData.append("plan_id", location.state._id);
            bodyFormData.append("auth_code", "Contigo-U$123");

            axios({
                method: "post",
                url: myurl,
                data: bodyFormData,
                headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: 'Barrer ' + localStorage.getItem("OM_Admin_ID") },
            })
                .then((response) => {
                    if (response?.data?.status == true) {

                        setDisable(false);
                        console.log("===SUCCESSFUL===", response?.data);
                        toast.success("Plan updated successfully");
                        navigate('/subscription-plans');

                    } else {
                        console.log("===Error===");
                        //console.log('===Error===', response?.data);
                        setDisable(false);
                    }
                })
                .catch((error) => {
                    console.log('Errors', error);
                    toast.error("Something went wrong");
                    setDisable(false);
                });
        }
        setChange(!change);
    };

    const InputEvent = (e) => {
        const newplanData = { ...editPlan };
        newplanData[e.target.name] = e.target.value;
        setEditPlan(newplanData);
    };

    const validate = () => {
        let input = editPlan;

        let errors = {};
        let isValid = true;

        if (!input["plan_name"]) {
            isValid = false;
            errors["name_err"] = "Please enter Plane name";
        }
        if (!input["description"]) {
            isValid = false;
            errors["description_err"] = "Please enter description";
        }

        if (input["amount"] > 0 && !input["amount"]) {
            isValid = false;
            errors["amount_err"] = "Please enter plan amount";
        }

        // if (!input["duration"] || input["duration"] == "duration") {
        //     isValid = false;
        //     errors["duration_err"] = "Please select plan duration";
        // }

        setErrors(errors);
        return isValid;
    };


    return (
        <>
            <Loader />
            <div
                id="page-container"
                className="fade page-sidebar-fixed page-header-fixed"
            >
                <Menu />
                <div id="content" className="content">
                    <ol className="breadcrumb float-xl-right">
                        <li className="breadcrumb-item">
                            <NavLink to="/dashboard">
                                <span className="basePath">Home</span>
                            </NavLink>
                        </li>
                        <li className="breadcrumb-item">
                            <NavLink to="/subscription-plans">
                                <span className="basePath">Subscription Plan</span>
                            </NavLink>
                        </li>
                        <li className="breadcrumb-item active currentPath">Edit Subscription Plan</li>
                    </ol>
                    <h1 className="page-header">Edit Subscription Plan</h1>

                    <div className="row">
                        <div className="col-xl-12 p-5">
                            <div
                                className="card "
                                style={{
                                    height: "auto",
                                    padding: "20px",
                                    borderRadius: "20px",
                                }}
                            >

                                <form className="profileForm row" >
                                    <div className="col-sm-6 mb-3">
                                        <label for="exampleInputEmail1" className="form-label"> Plan Name : </label>
                                        <input type="text" className="form-control ml-0" id="exampleInputName" placeholder="Enter Plan name here.." name="plan_name"
                                            value={editPlan.plan_name} onChange={InputEvent} />
                                        <div className="text-danger">{errors.name_err}</div>
                                    </div>

                                    <div className="col-sm-6 mb-3">
                                        <label for="exampleInputEmail1" className="form-label">Amount (For a month) : </label>
                                        <input type="number" min="0"
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 38 || e.keyCode === 40) {
                                                    e.preventDefault();
                                                }
                                            }} className="form-control ml-0" id="exampleInputName" placeholder="Enter Plan amount here.." name="amount"
                                            value={editPlan.amount} onChange={InputEvent} />
                                        <div className="text-danger">{errors.amount_err}</div>
                                    </div>

                                    <div className="col-sm-6 mb-3">
                                        <label for="exampleInputEmail1" className="form-label"> Description : </label>
                                        <CKEditor id="editor" editor={ClassicEditor} onChange={(e, editor) => { setEditPlan({ ...editPlan, description: editor.getData() }) }} data={editPlan.description} />
                                        {/* <textarea className="form-control ml-0" id="exampleInputName" placeholder="Enter description here.." name="description"
                                            onChange={InputEvent} value={editPlan.description} rows="3"></textarea> */}
                                        <div className="text-danger">{errors.description_err}</div>
                                    </div>

                                    <div className="col-sm-6 mb-3">
                                        <label for="exampleInputEmail1" className="form-label">Amount (For 6 months) : </label>
                                        <input type="number" min="0"
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 38 || e.keyCode === 40) {
                                                    e.preventDefault();
                                                }
                                            }} className="form-control ml-0" id="exampleInputName" placeholder="Enter Plan amount here.." name="amount_months"
                                            value={editPlan.amount_months} onChange={InputEvent} />
                                        <div className="text-danger">{errors.amount_months_err}</div>
                                        <label for="exampleInputEmail1" className="form-label" style={{ marginTop: "20px" }}>Amount (For a year) : </label>
                                        <input type="number" min="0"
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 38 || e.keyCode === 40) {
                                                    e.preventDefault();
                                                }
                                            }} className="form-control ml-0" id="exampleInputName" placeholder="Enter Plan amount here.." name="amount_year"
                                            value={editPlan.amount_year} onChange={InputEvent} />
                                        <div className="text-danger">{errors.amount_year_err}</div>
                                    </div>

                                    {/* <div className="col-sm-6 mb-3">
                                            <label for="exampleInputEmail1" className="form-label"> Select Type: </label>
                                            <select className="form-control ml-0" name="type"
                                                value={plan.type} onChange={InputEvent}>
                                                <option value="type">Select Type</option>
                                                <option value="School & company">School & Company</option>
                                                <option value="customer">Customer</option>
                                            </select>
                                            <div className="text-danger">{errors.type_err}</div>
                                        </div> */}

                                    {/* <div className="col-sm-6 mb-3">
                                        <label for="exampleInputEmail1" className="form-label"> Select Plan duration: </label>
                                        <select className="form-control ml-0" name="duration"
                                            value={editPlan.duration} onChange={InputEvent}>
                                            <option value="duration">Select Plan duration</option>
                                            <option value="1 month">1 month</option>
                                            <option value="3 month">3 month</option>
                                            <option value="6 month">6 month</option>
                                            <option value="1 year">1 year</option>
                                        </select>
                                        <div className="text-danger">{errors.duration_err}</div>
                                    </div> */}

                                    <div className="col-sm-6 mb-3">
                                        <label for="exampleInputImage" className="form-label">&nbsp;</label>
                                        <div className="ml-0 mt-1">
                                            <button type="button" onClick={(e) => submitHandler(e)} className="btn btn-success mr-2" disabled={disable}>
                                                {disable ? "Processing..." : "Update"}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />

            </div>
        </>
    )
}

export default EditPlan