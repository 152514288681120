import React, { useState, useEffect, useMemo } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Loader from "../include/Loader";
import Menu from "../include/Menu";
import Footer from "../include/Footer";
import { toast } from "react-toastify";
import axios from "axios";
import Spinner from "../include/Spinner";
import { CONTIGO_API_URL } from "../../BaseURL";
// import { D_URL } from "../baseurl";

function Dashboard() {
  const navigate = useNavigate();
  const location = useLocation();
  const [entity, setEntity] = useState({});
  const [disable, setDisable] = useState(false);

  const countData = () => {
    setDisable(true);
    const myurl = `${CONTIGO_API_URL}api/admin/dashboard-widget`;

    axios({
      method: "get",
      url: myurl,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + localStorage.getItem("ContigoU_Admin_ID"),
      },
    })
      .then((response) => {
        // console.log("@@", response?.data);
        if (response?.data?.status === true) {
          setEntity(response?.data?.data);
          // console.log(response?.data.data, "###");
          setDisable(false);
        } else {
          toast.error("Something went wrong");
          setDisable(false);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
        // console.log("Errors", error);
      });
  };
  // console.log("111", entity);
  useEffect(() => {
    if (localStorage.getItem("ContigoU_Admin_ID") == null) {
      navigate("/contigo-u-admin");
      return;
    }
    document.getElementById("page-loader").style.display = "none";
    var element = document.getElementById("page-container");
    element.classList.add("show");
    // if (disable) {
    countData();
    // }
  }, []);

  return (
    <>
      <Loader />

      <div
        id="page-container"
        className="fade page-sidebar-fixed page-header-fixed"
      >
        <Menu />

        <div id="content" className="content">
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item basePath ">
              <a href="/">Home</a>
            </li>
            <li className="breadcrumb-item active currentPath">Dashboard</li>
          </ol>
          <h1 className="page-header">Dashboard </h1>

          {/* ====================================== */}
          {/* {console.log("dfdfdf", entity)} */}
          {entity?.length !== 0 ? (
            <div className="row">
              <div className="col-xl-4 col-md-6">
                <div className="widget widget-stats bg-blue">
                  <div className="stats-icon">
                    <i className="fa fa-users"></i>
                  </div>
                  <div className="stats-info">
                    <h4>Total Patient</h4>
                    <p>{entity?.patientUser}</p>
                  </div>
                  <div className="stats-link">
                    <NavLink to="/patient-list">
                      View Details{" "}
                      <i className="fa fa-arrow-alt-circle-right"></i>
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-6">
                <div className="widget widget-stats bg-blue">
                  <div className="stats-icon">
                    <i class="fas fa-user-nurse"></i>
                  </div>
                  <div className="stats-info">
                    <h4>Total Nurse</h4>
                    <p>{entity?.nursingUser}</p>
                  </div>
                  <div className="stats-link">
                    <NavLink to="/nurse-list">
                      View Details{" "}
                      <i className="fa fa-arrow-alt-circle-right"></i>
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-6">
                <div className="widget widget-stats bg-blue">
                  <div className="stats-icon">
                    <i className="fas fa-hospital"></i>
                  </div>
                  <div className="stats-info">
                    <h4>Total Center Managment</h4>
                    <p>{entity?.center}</p>
                  </div>
                  <div className="stats-link">
                    <NavLink to="/center-management">
                      View Details{" "}
                      <i className="fa fa-arrow-alt-circle-right"></i>
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-6">
                <div className="widget widget-stats bg-blue">
                  <div className="stats-icon">
                    <i class="fas fa-envelope"></i>
                  </div>
                  <div className="stats-info">
                    <h4>Total Contact Us</h4>
                    <p>{entity?.contact_us}</p>
                  </div>
                  <div className="stats-link">
                    <NavLink to="/contact-us-list">
                      View Details{" "}
                      <i className="fa fa-arrow-alt-circle-right"></i>
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-6">
                <div className="widget widget-stats bg-blue">
                  <div className="stats-icon">
                    <i class="fas fa-user-nurse"></i>
                  </div>
                  <div className="stats-info">
                    <h4>Total Services</h4>
                    <p>{entity?.services}</p>
                  </div>
                  <div className="stats-link">
                    <NavLink style={{ height: "35px" }}></NavLink>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-6">
                <div className="widget widget-stats bg-blue">
                  <div className="stats-icon">
                    <i class="fas fa-medkit"></i>
                  </div>
                  <div className="stats-info">
                    <h4>Total Facilities</h4>
                    <p>{entity?.facilities}</p>
                  </div>
                  <div className="stats-link">
                    <NavLink style={{ height: "35px" }}></NavLink>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-6">
                <div className="widget widget-stats bg-blue">
                  <div className="stats-icon">
                    <i class="fas fa-medkit"></i>
                  </div>
                  <div className="stats-info">
                    <h4>Total Therapies</h4>
                    <p>{entity?.therapies}</p>
                  </div>
                  <div className="stats-link">
                    <NavLink style={{ height: "35px" }}></NavLink>
                  </div>
                </div>
              </div>

              {/* <div className="col-xl-4 col-md-6">
                <div className="widget widget-stats"  style={{ backgroundColor: "#" }}>
                  <div className="stats-icon">
                    <i className="fa fa-users"></i>
                  </div>
                  <div className="stats-info">
                    <h4>Total Facilities</h4>
                    <p>5</p>
                  </div>
                  <div className="stats-link">
                    <NavLink to="/user-list">
                      View Details{" "}
                      <i className="fa fa-arrow-alt-circle-right"></i>
                    </NavLink>
                  </div>
                </div>

              </div> */}
            </div>
          ) : (
            <Spinner />
          )}

          {/* ====================================== */}
        </div>

        <Footer />
      </div>
    </>
  );
}

export default Dashboard;
