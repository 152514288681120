import React, { useState, useEffect, useMemo } from 'react'
import Footer from '../include/Footer'
import Loader from '../include/Loader'
import Menu from '../include/Menu'

import { Dropdown, Table } from "react-bootstrap";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { CONTIGO_API_URL } from "../../BaseURL";
import { Pagination, Search } from '../Table';
import Spinner from '../include/Spinner';
import axios from 'axios';
import HTMLReactParser from 'html-react-parser';


const List = () => {


  const location = useLocation();
  const navigate = useNavigate();
  const [entity, setEntity] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [limit, setlimit] = useState(10);
  const [disable, setDisable] = useState(true);

  useEffect(() => {
    if (localStorage.getItem("ContigoU_Admin_ID") == null) {
      navigate("/contigo-u-admin");
      return;
    }
    document.getElementById("page-loader").style.display = "none";
    var element = document.getElementById("page-container");
    element.classList.add("show");
    if (disable == true) {
      setEntity([]);
      getSubscription();
    }
  }, [disable]);

  const commentsData = useMemo(() => {
    let computedComments = entity;

    if (search) {
      computedComments = computedComments.filter(
        (centers) =>
          centers.plan_name
            ?.toLowerCase()
            ?.includes(search?.toLowerCase()) ||
          centers?.description?.toLowerCase()?.includes(search?.toLowerCase()) ||
          centers?.duration?.toLowerCase()?.includes() ||
          centers?.amount?.toString()?.includes(search?.toLowerCase())
      );
    }
    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    return computedComments?.slice(
      (currentPage - 1) * limit,
      (currentPage - 1) * limit + limit
    );
  }, [currentPage, search, sorting, limit, entity]);


  const getSubscription = () => {
    const myurl = `${CONTIGO_API_URL}api/admin/get-subscriptionplan`;

    const bodyFormData = new URLSearchParams();
    bodyFormData.append('auth_code', "Contigo-U$123");
    bodyFormData.append("page", currentPage);

    axios({
      method: "post",
      url: myurl,
      data: bodyFormData,
      headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + localStorage.getItem('ContigoU_Admin_ID') },
    })
      .then(async (response) => {
        console?.log("@@", response?.data.data);
        if (response?.data?.status) {
          let data = await response?.data?.data?.map((e, i) => {
            return { ...e, sr_no: i + 1 };
          });
          console.log(data, "plans")
          setEntity(data);
          setDisable(false);
        } else {
          toast.error("Something went wrong");
          setDisable(false);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
        console?.log("Errors", error.response);
        setDisable(false);
      });
  };


  return (
    <>
      <Loader />
      <div
        id="page-container"
        className="fade page-sidebar-fixed page-header-fixed"
      >
        <Menu />
        <div id="content" className="content">
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item basePath ">
              <a href="/">Home</a>
            </li>
            <li className="breadcrumb-item active currentPath">
              Subscription Plans Management
            </li>
          </ol>
          <h1 className="page-header">Subscription Plans Management</h1>
          {disable == false ? (
            <div
              style={{
                backgroundColor: "white",
                padding: "20px",
                borderRadius: "20px",
                marginTop: "10px",
              }}
            >
              <div className="row w-100">
                <div className="mb-3 col-12 text-center">
                  {/* {entity?.length !== 3 && <Link to="/add-subscription-plans" className="btn btn-success  mb-3" style={{ float: "right", float: "right", padding: "3px 20px", fontSize: "16px" }}>Add Subscription Plan</Link>} */}
                  <div className="row">
                    <div className="col-xl-3 col-lg-3 col-sm-3 col-12 mb-3">
                      <div className="ml-0">
                        <div className="d-flex">
                          <h5 className="mt-2 mr-1">Search: </h5>
                          <Search
                            onSearch={(value) => {
                              setSearch(value);
                              setCurrentPage(1);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-sm-4 col-12 d-flex justify-content-end mb-3">
                      <div
                        style={{
                          color: "black",
                          fontSize: "12px",
                          fontWeight: "300",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                        }}
                        className="align-self-center"
                      >
                        <h5 className="mt-2 mr-1">Total Subscription Plans : {entity?.length} </h5>
                      </div>
                    </div>
                    <div className="col d-flex justify-content-end mb-3">
                      <div
                        style={{
                          color: "black",
                          fontSize: "12px",
                          fontWeight: "300",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                        }}
                        className="align-self-center"
                      >
                        <b>Rows per page :&nbsp;</b>
                      </div>
                      <div className="align-self-center">
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="none"
                            id="dropdown-basic"
                            style={{
                              cursor: "auto",
                              backgroundColor: "white",
                              borderColor: "#d5dbe0",
                              paddingBottom: "3px",
                              paddingTop: "3px",
                            }}
                          >
                            {limit}&nbsp;<i className="fa fa-caret-down"></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {limit !== 10 ? (
                              <>
                                <Dropdown.Item
                                  onClick={() => {
                                    setlimit(10);
                                  }}
                                >
                                  10
                                </Dropdown.Item>
                              </>
                            ) : null}

                            {limit !== 20 ? (
                              <>
                                <Dropdown.Item
                                  onClick={() => {
                                    setlimit(20);
                                  }}
                                >
                                  20
                                </Dropdown.Item>
                              </>
                            ) : null}

                            {limit !== 30 ? (
                              <>
                                <Dropdown.Item
                                  onClick={() => {
                                    setlimit(30);
                                  }}
                                >
                                  30
                                </Dropdown.Item>
                              </>
                            ) : null}

                            {limit !== 50 ? (
                              <>
                                <Dropdown.Item
                                  onClick={() => {
                                    setlimit(50);
                                  }}
                                >
                                  50
                                </Dropdown.Item>
                              </>
                            ) : null}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>

                  <div className="row ">
                    <div className="col-12">
                      <div className="table-responsive">
                        <Table striped bordered hover>
                          <thead>
                            {/* <TableHeader
                              headers={userHeader}
                              onSorting={(field, order) =>
                                setSorting({ field, order })
                              }
                            /> */}
                            <tr>
                              <th>Sr No.</th>
                              <th>Plan Name</th>
                              <th>Amount</th>
                              <th>Description</th>
                              <th>Actions</th>

                            </tr>
                          </thead>
                          <tbody>
                            {commentsData.map((e, i) => (
                              <tr>
                                <>
                                  <td>{e?.sr_no}</td>
                                  <td>{e?.plan_name}</td>
                                  <td>
                                    <div style={{ display: 'flex', flexDirection: "column", gap: "20px" }}>
                                      <span>${e?.amount} - For a month</span>
                                      <span>${e?.amount_months} - For 6 months</span>
                                      <span>${e?.amount_year} - For a year</span>
                                    </div>
                                  </td>
                                  <td>{HTMLReactParser(e?.description)}</td>
                                  {/* <td>{e?.first_name + " " + e?.last_name}</td> */}
                                  <td>
                                    <span className="text text-info icon-wrapper" data-tooltip="Edit" style={{ cursor: "pointer" }} onClick={() => navigate("/edit-subscription-plans", { state: e })}>
                                      <u>
                                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="27" height="27" viewBox="0 0 48 48">
                                          <path fill="#E57373" d="M42.583,9.067l-3.651-3.65c-0.555-0.556-1.459-0.556-2.015,0l-1.718,1.72l5.664,5.664l1.72-1.718C43.139,10.526,43.139,9.625,42.583,9.067"></path><path fill="#FF9800" d="M4.465 21.524H40.471999999999994V29.535H4.465z" transform="rotate(134.999 22.469 25.53)"></path><path fill="#B0BEC5" d="M34.61 7.379H38.616V15.392H34.61z" transform="rotate(-45.02 36.61 11.385)"></path><path fill="#FFC107" d="M6.905 35.43L5 43 12.571 41.094z"></path><path fill="#37474F" d="M5.965 39.172L5 43 8.827 42.035z"></path>
                                        </svg>
                                      </u>
                                    </span><br />
                                  </td>


                                </>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        {commentsData.length == 0 ? (
                          <div className="row d-flex justify-content-center w-100">
                            <div className="mx-auto  d-flex justify-content-center w-100">
                              <img
                                src="./assets/img/icon/no-location.png"
                                className="form-img__img-preview"
                                style={{ width: "100px", height: "100px" }}
                                alt=""
                              />
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div
                    className="mt-2 d-flex justify-content-sm-center justify-content-xs-center justify-content-lg-end"
                    style={{
                      overflowX: "auto",
                    }}
                  >
                    <Pagination
                      total={totalItems}
                      itemsPerPage={limit}
                      currentPage={currentPage}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Spinner />
          )}
        </div>
        <Footer />
      </div >
    </>
  )
}

export default List