import React, { useState, useEffect, useMemo } from 'react'
import { useLocation, useNavigate, NavLink } from 'react-router-dom';
import { CONTIGO_API_URL } from "../../BaseURL";
import { Dropdown, Table } from "react-bootstrap";

import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Loader from '../include/Loader';
import Menu from '../include/Menu';
import Footer from '../include/Footer';
import Spinner from '../include/Spinner';
import { Pagination, Search } from '../Table';
import moment from "moment/moment";


import OwlCarousel from "react-owl-carousel"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const NurseList = () => {

    useEffect(() => {
        if (localStorage.getItem("ContigoU_Admin_ID") == null) {
            navigate("/contigo-u-admin");
            return;
        }
        document.getElementById("page-loader").style.display = "none";
        var element = document.getElementById("page-container");
        element.classList.add("show");
        if (disable == true) {
            setEntity([])
            getCenterList();
        }
    }, []);

    const location = useLocation();
    const navigate = useNavigate();
    const [entity, setEntity] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [limit, setlimit] = useState(10);
    const [disable, setDisable] = useState(true);



    const coursesData = useMemo(() => {
        let computedPlan = entity;
        if (search) {
            computedPlan = computedPlan.filter(
                (course) =>
                    course?.first_name?.toLowerCase()?.includes(search?.toLowerCase()) ||
                    course?.last_name?.toLowerCase()?.includes(search?.toLowerCase()) ||
                    moment(course?.createdAt).format("YYYY-MM-DD hh:mm:ss a")?.includes(search?.toLowerCase()) ||

                    course?.email?.toLowerCase()?.includes(search?.toLowerCase()) ||
                    course?.phone_number?.toString()
                        ?.includes(search?.toLowerCase()) ||

                    course?.country_code?.toString()
                        ?.includes(search?.toLowerCase()) ||
                    course?.center_name?.toLowerCase()?.includes(search?.toLowerCase()) ||

                    course?.email?.toLowerCase()?.includes(search?.toLowerCase())

            );
        }
        setTotalItems(computedPlan?.length);

        //Sorting comments
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedPlan = computedPlan.sort(
                (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        //Current Page slice
        return computedPlan?.slice(
            (currentPage - 1) * limit,
            (currentPage - 1) * limit + limit
        );
    }, [entity, currentPage, search, sorting, limit])

    const getCenterList = () => {
        const myurl = `${CONTIGO_API_URL}api/admin/all-center`;
        const fd = new FormData();

        fd.append("page", currentPage);
        axios({
            method: "get",
            url: myurl,
            data: fd,
            headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Bearer " + localStorage.getItem('ContigoU_Admin_ID') },
        })
            .then(async (response) => {
                console?.log("@@", response?.data);

                if (response?.data?.status === true) {
                    let data = await response?.data?.data?.Pending.map((e, i) => {
                        return { ...e, sr_no: i + 1 };
                    });
                    setEntity(data);
                    setDisable(false);
                } else {
                    toast.error("Something went wrong");
                    setDisable(false);
                }
            })
            .catch((error) => {
                toast.error("Something went wrong");
                console?.log("Errors", error);
                setDisable(false);
            });
    };



    const deleteNurse = (id, first_name, last_name) => {
        Swal.fire({
            title: "Delete",
            text: `Are you sure to want to delete ${first_name} ${last_name}`,
            icon: "warning",
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Delete',
            showCancelButton: true,
            confirmButtonText: "Delete",
        }).then(async (res) => {
            if (res.isConfirmed) {
                const myurl = `${CONTIGO_API_URL}api/admin/delete-center`;
                var bodyFormData = new URLSearchParams();
                bodyFormData.append("user_id", id);
                axios({
                    method: "post",
                    url: myurl,
                    // url: 'http://localhost:5000/api/admin/delete-center',
                    data: bodyFormData,
                    headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Bearer " + localStorage.getItem('ContigoU_Admin_ID') },
                })
                    .then(async (response) => {
                        console?.log("@@", response?.data);
                        if (response?.data?.status === true) {
                            toast.success(response?.data?.message);
                            setDisable(true);
                            getCenterList();
                        } else {
                            toast.error(response?.data?.message);
                        }
                    })
                    .catch((error) => {
                        toast.error("Something went wrong");
                        console?.log("Errors", error);
                    });
            }
        });
    };

    return (
        <div>
            <>
                <Loader />
                <div
                    id="page-container"
                    className="fade page-sidebar-fixed page-header-fixed"
                >
                    <Menu />
                    <div id="content" className="content">
                        <ol className="breadcrumb float-xl-right">
                            <li className="breadcrumb-item basePath ">
                                <a href="/">Home</a>
                            </li>
                            <li className="breadcrumb-item active currentPath">
                                Nurses Management
                            </li>
                        </ol>
                        <h1 className="page-header">Nurses Management</h1>
                        {disable == false ? (
                            <div
                                style={{
                                    backgroundColor: "white",
                                    padding: "20px",
                                    borderRadius: "20px",
                                    marginTop: "10px",
                                }}
                            >
                                <div className="row w-100">
                                    <div className="mb-3 col-12 text-center">
                                        <div className="row">
                                            <div className="col-xl-3 col-lg-3 col-sm-3 col-12 mb-3">
                                                <div className="ml-0">
                                                    <div className="d-flex">
                                                        <h5 className="mt-2 mr-1">Search: </h5>
                                                        <Search
                                                            onSearch={(value) => {
                                                                setSearch(value);
                                                                setCurrentPage(1);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-sm-4 col-12 d-flex justify-content-end mb-3">
                                                <div
                                                    style={{
                                                        color: "black",
                                                        fontSize: "12px",
                                                        fontWeight: "300",
                                                        paddingTop: "0px",
                                                        paddingBottom: "0px",
                                                    }}
                                                    className="align-self-center"
                                                >
                                                    {/* <h5 className="mt-2 mr-1">Total Nurses : {entity?.length} </h5> */}
                                                </div>
                                            </div>
                                            <div className="col d-flex justify-content-end mb-3">
                                                <div
                                                    style={{
                                                        color: "black",
                                                        fontSize: "12px",
                                                        fontWeight: "300",
                                                        paddingTop: "0px",
                                                        paddingBottom: "0px",
                                                    }}
                                                    className="align-self-center"
                                                >
                                                    <b>Rows per page :&nbsp;</b>
                                                </div>
                                                <div className="align-self-center">
                                                    <Dropdown>
                                                        <Dropdown.Toggle
                                                            variant="none"
                                                            id="dropdown-basic"
                                                            style={{
                                                                cursor: "auto",
                                                                backgroundColor: "white",
                                                                borderColor: "#d5dbe0",
                                                                paddingBottom: "3px",
                                                                paddingTop: "3px",
                                                            }}
                                                        >
                                                            {limit}&nbsp;<i className="fa fa-caret-down"></i>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            {limit !== 10 ? (
                                                                <>
                                                                    <Dropdown.Item
                                                                        onClick={() => {
                                                                            setlimit(10);
                                                                        }}
                                                                    >
                                                                        10
                                                                    </Dropdown.Item>
                                                                </>
                                                            ) : null}

                                                            {limit !== 20 ? (
                                                                <>
                                                                    <Dropdown.Item
                                                                        onClick={() => {
                                                                            setlimit(20);
                                                                        }}
                                                                    >
                                                                        20
                                                                    </Dropdown.Item>
                                                                </>
                                                            ) : null}

                                                            {limit !== 30 ? (
                                                                <>
                                                                    <Dropdown.Item
                                                                        onClick={() => {
                                                                            setlimit(30);
                                                                        }}
                                                                    >
                                                                        30
                                                                    </Dropdown.Item>
                                                                </>
                                                            ) : null}

                                                            {limit !== 50 ? (
                                                                <>
                                                                    <Dropdown.Item
                                                                        onClick={() => {
                                                                            setlimit(50);
                                                                        }}
                                                                    >
                                                                        50
                                                                    </Dropdown.Item>
                                                                </>
                                                            ) : null}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row ">
                                            <div className="col-12">
                                                <div className="table-responsive">
                                                    <Table striped bordered hover>
                                                        <thead>
                                                            <tr>
                                                                <th>Sr No.</th>
                                                                <th>License</th>
                                                                <th>Name</th>
                                                                <th>Email</th>
                                                                <th>Center Name</th>
                                                                <th>Mobile Number</th>
                                                                <th>State</th>
                                                                <th>Total Beds</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {coursesData?.map((e, i) => (
                                                                <tr key={e?._id}>
                                                                    <>
                                                                        <td>{search ? i + 1 : e?.sr_no}</td>
                                                                        <td>
                                                                            {e?.license?.match("undefined") !=
                                                                                "undefined" ? (
                                                                                e?.license != null ? (
                                                                                    <img className="img-round"
                                                                                        src={e?.license}
                                                                                        width="30px"
                                                                                        height="30px"
                                                                                        alt="nurse"
                                                                                    />
                                                                                ) : (
                                                                                    <img className="img-round"
                                                                                        src="./assets/img/icon/event.png"
                                                                                        width="30px"
                                                                                        height="30px"
                                                                                        alt="nurse"
                                                                                    />
                                                                                )
                                                                            ) : (
                                                                                <img className="img-round"
                                                                                    src="./assets/img/icon/event.png"
                                                                                    width="30px"
                                                                                    height="30px"
                                                                                    alt="nurse"
                                                                                />
                                                                            )}
                                                                        </td>
                                                                        <td>{e?.first_name} {e?.last_name}</td>
                                                                        <td>{e?.email}</td>
                                                                        <td>{e?.center_name}</td>
                                                                        <td>{e?.country_code}-{e?.phone_number}</td>
                                                                        <td>{e?.state}</td>
                                                                        <td>{e?.No_of_bed}</td>
                                                                        <td>
                                                                            <span className="text text-primary icon-wrapper" data-tooltip="View Details" style={{ cursor: "pointer" }} onClick={() => navigate("/nurse-details", { state: e, })}>
                                                                                <u>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="27" height="27" viewBox="0 0 48 48">
                                                                                        <path fill="#2196f3" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"></path><path fill="#fff" d="M22 22h4v11h-4V22zM26.5 16.5c0 1.379-1.121 2.5-2.5 2.5s-2.5-1.121-2.5-2.5S22.621 14 24 14 26.5 15.121 26.5 16.5z"></path>
                                                                                    </svg>
                                                                                </u>
                                                                            </span><br />
                                                                            <span className="text text-secondary icon-wrapper" data-tooltip="Delete" style={{ cursor: "pointer" }} onClick={() => deleteNurse(e?.id, e?.first_name, e?.last_name)}>
                                                                                <u>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="27" height="27" viewBox="0 0 48 48">
                                                                                        <path fill="#9575CD" d="M34,12l-6-6h-8l-6,6h-3v28c0,2.2,1.8,4,4,4h18c2.2,0,4-1.8,4-4V12H34z"></path><path fill="#7454B3" d="M24.5 39h-1c-.8 0-1.5-.7-1.5-1.5v-19c0-.8.7-1.5 1.5-1.5h1c.8 0 1.5.7 1.5 1.5v19C26 38.3 25.3 39 24.5 39zM31.5 39L31.5 39c-.8 0-1.5-.7-1.5-1.5v-19c0-.8.7-1.5 1.5-1.5l0 0c.8 0 1.5.7 1.5 1.5v19C33 38.3 32.3 39 31.5 39zM16.5 39L16.5 39c-.8 0-1.5-.7-1.5-1.5v-19c0-.8.7-1.5 1.5-1.5l0 0c.8 0 1.5.7 1.5 1.5v19C18 38.3 17.3 39 16.5 39z"></path><path fill="#B39DDB" d="M11,8h26c1.1,0,2,0.9,2,2v2H9v-2C9,8.9,9.9,8,11,8z"></path>
                                                                                    </svg>
                                                                                </u>
                                                                            </span>
                                                                        </td>
                                                                    </>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                    {coursesData?.length === 0 ? (
                                                        <div className="row d-flex justify-content-center w-100">
                                                            <div className="mx-auto d-flex justify-content-center w-100">
                                                                <img
                                                                    src="./assets/img/icon/no-location.png"
                                                                    className="form-img__img-preview"
                                                                    style={{ width: "100px", height: "100px" }}
                                                                    alt=""
                                                                />
                                                            </div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="mt-2 d-flex justify-content-sm-center justify-content-xs-center justify-content-lg-end"
                                            style={{
                                                overflowX: "auto",
                                            }}
                                        >
                                            <Pagination
                                                total={totalItems}
                                                itemsPerPage={limit}
                                                currentPage={currentPage}
                                                onPageChange={(page) => setCurrentPage(page)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <Spinner />
                        )}
                    </div>
                    <Footer />
                </div>
            </>
        </div>
    )
}

export default NurseList