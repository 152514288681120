import { TableHeader, Pagination, Search } from "../Table";
import React, { useState, useEffect, useMemo } from "react";
import Loader from "../include/Loader";
import Menu from "../include/Menu";
import Footer from "../include/Footer";
import { Dropdown, Table } from "react-bootstrap";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
// import HistoryIcon from "@material-ui/icons/History";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Spinner from "../include/Spinner";
import { CONTIGO_API_URL } from "../../BaseURL";


const Center = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const [entity, setEntity] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [limit, setlimit] = useState(10);
    const [disable, setDisable] = useState(true);
    const [entityTwo, setEntityTwo] = useState([]);

    const [centers, setCenters] = useState({ pending: [], approved: [], blocked: [] });
    const [active, setActive] = useState(location?.state?.active || 'pending');
    const [displayCenters, setDisplayCenters] = useState([]);

    useEffect(() => {
        if (location?.state?.active) {
            setActive(location?.state?.active)
        }

        if (localStorage.getItem("ContigoU_Admin_ID") == null) {
            navigate("/contigo-u-admin");
            return;
        }
        document.getElementById("page-loader").style.display = "none";
        var element = document.getElementById("page-container");
        element.classList.add("show");
        if (disable == true) {
            setEntityThree([])
            setEntityFour([]);
            // setEntityTwo([])
            getCenterList();
        }
    }, []);

    useEffect(() => {
        getCenterList()
    }, [active])

    const centersData = useMemo(() => {
        let computedComments = displayCenters;

        if (search) {
            computedComments = computedComments.filter(
                (centers) =>
                    centers.center_name
                        ?.toLowerCase()
                        ?.includes(search?.toLowerCase()) ||
                    Object.values(Object.fromEntries(Object.entries(centers?.address).slice(0, 4))).join(', ')?.toLowerCase()?.includes(search?.toLowerCase()) ||
                    centers?.description?.toLowerCase()?.includes(search?.toLowerCase()) ||
                    // centers.female_beds
                    //     ?.toString()
                    //     ?.includes(search?.toLowerCase()) ||
                    centers.total_beds
                        ?.toString()
                        ?.includes(search?.toLowerCase())
                // centers.timings?.toLowerCase()?.includes(search?.toLowerCase()) ||
                // centers.supported_insurance?.toLowerCase()?.includes(search?.toLowerCase()) ||
                // centers.mobileno
                //     ?.toLowerCase()
                //     ?.includes(search?.toLowerCase()) ||

            );
        }
        setTotalItems(computedComments?.length);

        //Sorting comments
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort(
                (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        //Current Page slice
        return computedComments?.slice(
            (currentPage - 1) * limit,
            (currentPage - 1) * limit + limit
        );
    }, [currentPage, search, sorting, limit, displayCenters]);



    const [entityThree, setEntityThree] = useState([]);
    const [entityFour, setEntityFour] = useState([]);


    const getCenterList = () => {
        const myurl = `${CONTIGO_API_URL}api/admin/all-center`;
        const fd = new URLSearchParams();

        fd.append("page", currentPage);
        axios({
            method: "get",
            url: myurl,
            data: fd,
            headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Bearer " + localStorage.getItem('ContigoU_Admin_ID') },
        })
            .then(async (response) => {
                console?.log("@@", response?.data);

                if (response?.data?.status === true) {
                    // let data = await response?.data?.data?.map((e, i) => {
                    //     return { ...e, sr_no: i + 1 };
                    // });
                    // setEntity(data);

                    setEntityTwo(response?.data?.data?.Pending);
                    setEntityThree(response?.data?.data?.Pending_approval);
                    setEntityFour(response?.data?.data?.Approve);

                    setCenters({
                        pending: response?.data?.data?.Pending_approval,
                        approved: response?.data?.data?.Approve,
                        blocked: response?.data?.data?.Block
                    });
                    // setDisplayCenters(response?.data?.data?.Pending_approval);

                    if (active === 'approved') {
                        setDisplayCenters(response?.data?.data?.Approve);
                    } else if (active === 'blocked') {
                        setDisplayCenters(response?.data?.data?.Block);
                    } else {
                        setDisplayCenters(response?.data?.data?.Pending_approval);
                    }


                    setDisable(false);
                } else {
                    toast.error("Something went wrong");
                    setDisable(false);
                }
            })
            .catch((error) => {
                toast.error("Something went wrong");
                console?.log("Errors", error);
                setDisable(false);
            });
    };

    const switchStatus = (status) => {
        let el;
        switch (status) {
            // case 0:
            //     el = <span className="text-info">Unverified</span>;
            //     break;
            case 1:
                el = <span className="badge badge-pill badge-warning">Pending</span>;
                break;
            case 2:
                el = <span className="badge badge-pill badge-success">Approved</span>;
                break;
            case 3:
                el = <span className="badge badge-pill badge-info">Blocked</span>;
                break;
            case 5:
                el = <span className="badge badge-pill badge-danger">Rejected</span>;
                break; // This break statement is missing in your original code
            default:
                el = <span></span>;
                break;
        }
        return el;
    };

    const approveCenter = (id, name) => {
        console.log(id, "approve")
        Swal.fire({
            title: "Approve",
            text: `Are you sure to want to approve ${name}`,
            icon: "success",
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Approve',
            showCancelButton: true,
            confirmButtonText: "Approve",
        }).then(async (res) => {
            if (res.isConfirmed) {
                const myurl = `${CONTIGO_API_URL}api/admin/aprove-center`;
                var bodyFormData = new URLSearchParams();
                console.log(id, "/////")
                bodyFormData.append("user_id", id);
                axios({
                    method: "post",
                    url: myurl,
                    // url: 'http://localhost:5000/api/admin/approve-center',
                    data: bodyFormData,
                    headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Bearer " + localStorage.getItem('ContigoU_Admin_ID') },
                })
                    .then((response) => {
                        console?.log("@@", response?.data);
                        if (response?.data?.status === true) {
                            toast.success(response?.data?.message);
                            // setDisable(true);
                            // getCenterList();
                            setActive('approved')
                        } else {
                            toast.error(response?.data?.message);
                        }
                    })
                    .catch((error) => {
                        toast.error("Something went wrong");
                        console?.log("Errors", error);
                    });
            }
        });
    };

    const rejectCenter = (id, name) => {
        Swal.fire({
            title: "Reject",
            text: `Are you sure to want to ${name}`,
            icon: "warning",
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: "Reject",
            showCancelButton: true,
            input: 'text', // Add a text input field
            inputPlaceholder: 'Reason for rejection', // Placeholder text for the input field
            confirmButtonText: "Reject",
            inputValidator: (value) => {
                if (!value) {
                    return 'Please enter a reason for rejection'; // Display error message if reason is empty
                }
            }
        }).then((res) => {
            if (res.isConfirmed) {
                const myurl = `${CONTIGO_API_URL}api/admin/reject-center`;
                var bodyFormData = new URLSearchParams();
                bodyFormData.append("user_id", id);
                bodyFormData.append("reason", res?.value);
                axios({
                    method: "post",
                    url: myurl,
                    // url: 'http://localhost:5000/api/admin/blocked-center',
                    data: bodyFormData,
                    headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + localStorage.getItem('ContigoU_Admin_ID') },
                })
                    .then((response) => {
                        console?.log("@@", response?.data);
                        if (response?.data?.status === true) {
                            toast.success(response?.data?.message);
                            // setDisable(true);
                            getCenterList();
                            // setActive('pending')

                        } else {
                            toast.error(response?.data?.message);
                        }
                    })
                    .catch((error) => {
                        toast.error("Something went wrong");
                        console?.log("Errors", error);
                    });
            }
        });
    };

    const blockCenter = (id, name) => {
        Swal.fire({
            title: "Block",
            text: `Are you sure to want to ${name}`,
            icon: "warning",
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: "Block",
            showCancelButton: true,
            confirmButtonText: "Block"
        }).then((res) => {
            if (res.isConfirmed) {
                const myurl = `${CONTIGO_API_URL}api/admin/block-center`;
                var bodyFormData = new URLSearchParams();
                bodyFormData.append("user_id", id);
                axios({
                    method: "post",
                    url: myurl,
                    // url: 'http://localhost:5000/api/admin/blocked-center',
                    data: bodyFormData,
                    headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Bearer " + localStorage.getItem('ContigoU_Admin_ID') },
                })
                    .then((response) => {
                        console?.log("@@", response?.data);
                        if (response?.data?.status === true) {
                            toast.success(response?.data?.message);
                            // setDisable(true);
                            // getCenterList();
                            // handleCenter('blocked')
                            setActive('blocked');

                        } else {
                            toast.error(response?.data?.message);
                        }
                    })
                    .catch((error) => {
                        toast.error("Something went wrong");
                        console?.log("Errors", error);
                    });
            }
        });
    };

    const unblockCenter = (id, name) => {
        Swal.fire({
            title: "Unblock",
            text: `Are you sure to want to ${name}`,
            icon: "warning",
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: "Unblock",
            showCancelButton: true,
            confirmButtonText: "Unblock"
        }).then((res) => {
            if (res.isConfirmed) {
                const myurl = `${CONTIGO_API_URL}api/admin/block-center`;
                var bodyFormData = new URLSearchParams();
                bodyFormData.append("user_id", id);
                axios({
                    method: "post",
                    url: myurl,
                    data: bodyFormData,
                    headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Bearer " + localStorage.getItem('ContigoU_Admin_ID') },
                })
                    .then((response) => {
                        console?.log("@@", response?.data);
                        if (response?.data?.status === true) {
                            toast.success(response?.data?.message);
                            // setDisable(true);
                            // getCenterList();
                            // handleCenter('approved')
                            setActive('approved');

                        } else {
                            toast.error(response?.data?.message);
                        }
                    })
                    .catch((error) => {
                        toast.error("Something went wrong");
                        console?.log("Errors", error);
                    });
            }
        });
    };




    const deletePending = (id, name) => {
        Swal.fire({
            title: "Delete",
            text: `Are you sure to want to delete ${name}`,
            icon: "warning",
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Delete',
            showCancelButton: true,
            confirmButtonText: "Delete",
        }).then(async (res) => {
            if (res.isConfirmed) {
                const myurl = `${CONTIGO_API_URL}api/admin/delete-center`;
                var bodyFormData = new URLSearchParams();
                bodyFormData.append("user_id", id);
                axios({
                    method: "post",
                    url: myurl,
                    data: bodyFormData,
                    headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + localStorage.getItem('ContigoU_Admin_ID') },
                })
                    .then(async (response) => {
                        console?.log("@@", response?.data);
                        if (response?.data?.status === true) {
                            toast.success(response?.data?.message);
                            setDisable(true);
                            getCenterList();
                        } else {
                            toast.error(response?.data?.message);
                        }
                    })
                    .catch((error) => {
                        toast.error("Something went wrong");
                        console?.log("Errors", error);
                    });
            }
        });
    };

    const handleCenter = (type) => {
        setActive(type);
        setDisplayCenters(centers[type])
        // console.log(centers, centers[type]);
    }



    return (
        <div>
            <>
                <Loader />

                <div
                    id="page-container"
                    className="fade page-sidebar-fixed page-header-fixed"
                >
                    <Menu />

                    <div id="content" className="content">
                        <ol className="breadcrumb float-xl-right">
                            <li className="breadcrumb-item basePath ">
                                <a href="/">Home</a>
                            </li>
                            <li className="breadcrumb-item active currentPath">
                                Center Management
                            </li>
                        </ol>
                        <h1 className="page-header">Center Management</h1>
                        {disable == false ? (
                            <div
                                style={{
                                    backgroundColor: "white",
                                    padding: "20px",
                                    borderRadius: "20px",
                                    marginTop: "10px",
                                }}
                            >
                                <div className="row w-100">
                                    <div className="mb-3 col-12 text-center">
                                        <div className="row">
                                            <div className="col-xl-3 col-lg-3 col-sm-3 col-12 mb-3">
                                                <div className="ml-0">
                                                    <div className="d-flex">
                                                        <h5 className="mt-2 mr-1">Search: </h5>
                                                        <Search
                                                            onSearch={(value) => {
                                                                setSearch(value);
                                                                setCurrentPage(1);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-sm-4 col-12 d-flex justify-content-end mb-3">
                                                <div
                                                    style={{
                                                        color: "black",
                                                        fontSize: "12px",
                                                        fontWeight: "300",
                                                        paddingTop: "0px",
                                                        paddingBottom: "0px",
                                                    }}
                                                    className="align-self-center"
                                                >
                                                    {/* <h5 className="mt-2 mr-1">Total Centers : {centersData?.length} </h5> */}
                                                </div>
                                            </div>
                                            <div className="col d-flex justify-content-end mb-3">
                                                <div
                                                    style={{
                                                        color: "black",
                                                        fontSize: "12px",
                                                        fontWeight: "300",
                                                        paddingTop: "0px",
                                                        paddingBottom: "0px",
                                                    }}
                                                    className="align-self-center"
                                                >
                                                    <b>Rows per page :&nbsp;</b>
                                                </div>
                                                <div className="align-self-center">
                                                    <Dropdown>
                                                        <Dropdown.Toggle
                                                            variant="none"
                                                            id="dropdown-basic"
                                                            style={{
                                                                cursor: "auto",
                                                                backgroundColor: "white",
                                                                borderColor: "#d5dbe0",
                                                                paddingBottom: "3px",
                                                                paddingTop: "3px",
                                                            }}
                                                        >
                                                            {limit}&nbsp;<i className="fa fa-caret-down"></i>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            {limit !== 10 ? (
                                                                <>
                                                                    <Dropdown.Item
                                                                        onClick={() => {
                                                                            setlimit(10);
                                                                        }}
                                                                    >
                                                                        10
                                                                    </Dropdown.Item>
                                                                </>
                                                            ) : null}

                                                            {limit !== 20 ? (
                                                                <>
                                                                    <Dropdown.Item
                                                                        onClick={() => {
                                                                            setlimit(20);
                                                                        }}
                                                                    >
                                                                        20
                                                                    </Dropdown.Item>
                                                                </>
                                                            ) : null}

                                                            {limit !== 30 ? (
                                                                <>
                                                                    <Dropdown.Item
                                                                        onClick={() => {
                                                                            setlimit(30);
                                                                        }}
                                                                    >
                                                                        30
                                                                    </Dropdown.Item>
                                                                </>
                                                            ) : null}

                                                            {limit !== 50 ? (
                                                                <>
                                                                    <Dropdown.Item
                                                                        onClick={() => {
                                                                            setlimit(50);
                                                                        }}
                                                                    >
                                                                        50
                                                                    </Dropdown.Item>
                                                                </>
                                                            ) : null}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row ">
                                            <div className="col-12">
                                                <div className="table-responsive">

                                                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                                        <li className="nav-item">
                                                            <a className={active === 'pending' ? "nav-link active" : "nav-link"} id="pills-profile-tab" data-toggle="pill" role="tab" aria-controls="pills-profile" aria-selected="false" onClick={() => handleCenter('pending')}>Pending Centers</a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className={active === 'approved' ? "nav-link active" : "nav-link"} id="pills-contact-tab" data-toggle="pill" role="tab" aria-controls="pills-contact" aria-selected="false" onClick={() => handleCenter('approved')}>Approved Centers</a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className={active === 'blocked' ? "nav-link active" : "nav-link"} id="pills-contact-tab" data-toggle="pill" role="tab" aria-controls="pills-contact" aria-selected="false" onClick={() => handleCenter('blocked')}>Blocked Centers</a>
                                                        </li>
                                                    </ul>
                                                    <div className="tab-content" id="pills-tabContent">
                                                        <div className="tab-pane fade active show" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                                            <Table striped bordered hover>
                                                                <thead>

                                                                    <tr>
                                                                        <th>Sr No.</th>
                                                                        <th>Center Image</th>
                                                                        {/* <th>Name</th> */}
                                                                        <th>Center Name</th>
                                                                        <th>Center Address</th>
                                                                        <th>Center Description</th>
                                                                        <th>Total Beds</th>
                                                                        {/* <th>Male Beds</th>
                                                                        <th>Female Beds</th> */}
                                                                        <th>Room Type</th>
                                                                        {/* <th>Timings</th> */}
                                                                        <th>Status</th>
                                                                        <th>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {centersData?.map((elem, i) =>
                                                                        <>
                                                                            <tr key={elem?.id}>
                                                                                <td>{i + 1}</td>
                                                                                <td>
                                                                                    {elem?.center_image.length > 0 ? (
                                                                                        <img
                                                                                            className="img-round"
                                                                                            src={elem.center_image[0]}
                                                                                            width="auto"
                                                                                            height="40px"
                                                                                            alt="center_image"
                                                                                        />
                                                                                    ) : (
                                                                                        <img
                                                                                            className="img-round"
                                                                                            src="./assets/img/icon/null_icon.png"
                                                                                            width="30px"
                                                                                            height="30px"
                                                                                            alt="null_icon"
                                                                                        />
                                                                                    )}
                                                                                    {/* <img src={e?.center_image} style={{ width: "auto", height: "50px", borderRadius: "9px" }} /> */}
                                                                                </td>
                                                                                <td>{elem?.center_name}</td>
                                                                                {/* <td>{Object.values(Object.fromEntries(Object.entries(elem?.address).slice(0, 4))).join(', ')}</td> */}
                                                                                <td>{elem?.address.address}</td>
                                                                                {/* <td>{e?.first_name} {e?.last_name}</td> */}
                                                                                <td>{elem?.description?.length > 50 ? elem.description.slice(0, 50) + '...' : elem.description || ""}</td>

                                                                                <td>{elem.No_of_bed}</td>
                                                                                {/* <td>{elem.male_beds}</td>
                                                                                <td>{elem.female_beds}</td> */}
                                                                                <td>{elem.room_type === 1 ? 'Private Rooms' : elem?.room_type === 2 ? 'Double Rooms' : elem?.room_type === 3 ? 'Triples Rooms' : elem?.room_type === 4 ? 'Quads Rooms' : ""} </td>
                                                                                {/* <td>{elem.timing}</td> */}

                                                                                <td>
                                                                                    {switchStatus(elem?.status)}
                                                                                    {/* {elem?.status} */}
                                                                                </td>

                                                                                <td>
                                                                                    <span className="text text-primary icon-wrapper" data-tooltip="View Details" style={{ cursor: "pointer" }} onClick={() => navigate("/pending-center-details", { state: { ...elem, active } })}>
                                                                                        <u>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="27" height="27" viewBox="0 0 48 48">
                                                                                                <path fill="#2196f3" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"></path><path fill="#fff" d="M22 22h4v11h-4V22zM26.5 16.5c0 1.379-1.121 2.5-2.5 2.5s-2.5-1.121-2.5-2.5S22.621 14 24 14 26.5 15.121 26.5 16.5z"></path>
                                                                                            </svg>
                                                                                        </u></span><br />

                                                                                    {elem.status === 1 && (
                                                                                        <>
                                                                                            <span className="text text-success icon-wrapper" data-tooltip="Approve" style={{ cursor: "pointer" }} onClick={() => approveCenter(elem.id, elem.center_name)}>
                                                                                                <u>
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="27" height="27" viewBox="0 0 48 48">
                                                                                                        <path fill="#c8e6c9" d="M36,42H12c-3.314,0-6-2.686-6-6V12c0-3.314,2.686-6,6-6h24c3.314,0,6,2.686,6,6v24C42,39.314,39.314,42,36,42z"></path><path fill="#4caf50" d="M34.585 14.586L21.014 28.172 15.413 22.584 12.587 25.416 21.019 33.828 37.415 17.414z"></path>
                                                                                                    </svg>
                                                                                                </u></span><br />
                                                                                            <span className="text text-danger icon-wrapper" data-tooltip="Reject" style={{ cursor: "pointer" }} onClick={() => rejectCenter(elem.id, elem.center_name)}>
                                                                                                <u>
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="27" height="27" viewBox="0 0 48 48">
                                                                                                        <linearGradient id="wRKXFJsqHCxLE9yyOYHkza_fYgQxDaH069W_gr1" x1="9.858" x2="38.142" y1="9.858" y2="38.142" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#f44f5a"></stop><stop offset=".443" stop-color="#ee3d4a"></stop><stop offset="1" stop-color="#e52030"></stop></linearGradient><path fill="url(#wRKXFJsqHCxLE9yyOYHkza_fYgQxDaH069W_gr1)" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"></path><path d="M33.192,28.95L28.243,24l4.95-4.95c0.781-0.781,0.781-2.047,0-2.828l-1.414-1.414	c-0.781-0.781-2.047-0.781-2.828,0L24,19.757l-4.95-4.95c-0.781-0.781-2.047-0.781-2.828,0l-1.414,1.414	c-0.781,0.781-0.781,2.047,0,2.828l4.95,4.95l-4.95,4.95c-0.781,0.781-0.781,2.047,0,2.828l1.414,1.414	c0.781,0.781,2.047,0.781,2.828,0l4.95-4.95l4.95,4.95c0.781,0.781,2.047,0.781,2.828,0l1.414-1.414	C33.973,30.997,33.973,29.731,33.192,28.95z" opacity=".05"></path><path d="M32.839,29.303L27.536,24l5.303-5.303c0.586-0.586,0.586-1.536,0-2.121l-1.414-1.414	c-0.586-0.586-1.536-0.586-2.121,0L24,20.464l-5.303-5.303c-0.586-0.586-1.536-0.586-2.121,0l-1.414,1.414	c-0.586,0.586-0.586,1.536,0,2.121L20.464,24l-5.303,5.303c-0.586,0.586-0.586,1.536,0,2.121l1.414,1.414	c0.586,0.586,1.536,0.586,2.121,0L24,27.536l5.303,5.303c0.586,0.586,1.536,0.586,2.121,0l1.414-1.414	C33.425,30.839,33.425,29.889,32.839,29.303z" opacity=".07"></path><path fill="#fff" d="M31.071,15.515l1.414,1.414c0.391,0.391,0.391,1.024,0,1.414L18.343,32.485	c-0.391,0.391-1.024,0.391-1.414,0l-1.414-1.414c-0.391-0.391-0.391-1.024,0-1.414l14.142-14.142	C30.047,15.124,30.681,15.124,31.071,15.515z"></path><path fill="#fff" d="M32.485,31.071l-1.414,1.414c-0.391,0.391-1.024,0.391-1.414,0L15.515,18.343	c-0.391-0.391-0.391-1.024,0-1.414l1.414-1.414c0.391-0.391,1.024-0.391,1.414,0l14.142,14.142	C32.876,30.047,32.876,30.681,32.485,31.071z"></path>
                                                                                                    </svg>
                                                                                                </u>
                                                                                            </span><br />
                                                                                            {/* <span className="text text-secondary" style={{ cursor: "pointer" }} onClick={() => deletePending(elem.id, elem.center_name)}><u>Delete</u></span> */}
                                                                                        </>
                                                                                    )}


                                                                                    {elem?.status === 2 && (
                                                                                        <>
                                                                                            {elem?.status !== 3 && (
                                                                                                <>
                                                                                                    <span className="text text-info icon-wrapper" data-tooltip="Block" style={{ cursor: "pointer" }} onClick={() => blockCenter(elem.id, elem.center_name)}>
                                                                                                        <u>
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="27" height="27" viewBox="0 0 48 48">
                                                                                                                <path fill="#424242" d="M24,4c-5.5,0-10,4.5-10,10v4h4v-4c0-3.3,2.7-6,6-6s6,2.7,6,6v4h4v-4C34,8.5,29.5,4,24,4z"></path><path fill="#FB8C00" d="M36,44H12c-2.2,0-4-1.8-4-4V22c0-2.2,1.8-4,4-4h24c2.2,0,4,1.8,4,4v18C40,42.2,38.2,44,36,44z"></path><path fill="#C76E00" d="M24 28A3 3 0 1 0 24 34A3 3 0 1 0 24 28Z"></path>
                                                                                                            </svg>
                                                                                                        </u>
                                                                                                    </span><br />
                                                                                                </>
                                                                                            )}
                                                                                        </>
                                                                                    )}

                                                                                    {elem?.status === 3 && (
                                                                                        <>
                                                                                            <span className="text text-success icon-wrapper" data-tooltip="Unblock" style={{ cursor: "pointer" }} onClick={() => unblockCenter(elem.id, elem.center_name)}>
                                                                                                <u>
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="27" height="27" viewBox="0 0 48 48">
                                                                                                        <path fill="#424242" d="M24,4c-5.5,0-10,4.5-10,10v4h4v-4c0-3.3,2.7-6,6-6s6,2.7,6,6h4C34,8.5,29.5,4,24,4z"></path><path fill="#FB8C00" d="M36,44H12c-2.2,0-4-1.8-4-4V22c0-2.2,1.8-4,4-4h24c2.2,0,4,1.8,4,4v18C40,42.2,38.2,44,36,44z"></path><path fill="#C76E00" d="M24 28A3 3 0 1 0 24 34A3 3 0 1 0 24 28Z"></path>
                                                                                                    </svg>
                                                                                                </u>
                                                                                            </span><br />

                                                                                        </>
                                                                                    )
                                                                                    }



                                                                                    {elem.status === 2 && (
                                                                                        <>
                                                                                            {/* <span className="text text-info" style={{ cursor: "pointer" }} onClick={() => blockCenter(elem.id, elem.center_name)}><u>Block</u></span><br /> */}
                                                                                            {/* <span className="text text-secondary" style={{ cursor: "pointer" }} onClick={() => deletePending(elem.id, elem.center_name)}><u>Delete</u></span> */}
                                                                                            {elem.status === 5 && (
                                                                                                <span className="text text-secondary" style={{ cursor: "pointer" }} onClick={() => deletePending(elem.id, elem.center_name)}><u>Delete</u></span>
                                                                                            )}
                                                                                        </>
                                                                                    )}
                                                                                    {/* {elem?.status === 5 || elem?.status === 3 ? (
                                                                                        <span className="text text-secondary" style={{ cursor: "pointer" }} onClick={() => deletePending(elem.id, elem.center_name)}><u>Delete</u></span>
                                                                                    ) : null} */}



                                                                                </td>

                                                                            </tr>
                                                                        </>
                                                                    )
                                                                    }

                                                                </tbody>
                                                            </Table>
                                                            {centersData?.length === 0 ? (
                                                                <span className="row d-flex justify-content-center w-100">
                                                                    <span className="mx-auto d-flex justify-content-center w-100">
                                                                        <img
                                                                            src="./assets/img/icon/no-location.png"
                                                                            className="form-img__img-preview"
                                                                            style={{ width: "100px", height: "100px" }}
                                                                            alt=""
                                                                        />
                                                                    </span>
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="mt-2 d-flex justify-content-sm-center justify-content-xs-center justify-content-lg-end"
                                            style={{
                                                overflowX: "auto",
                                            }}
                                        >
                                            <Pagination
                                                total={totalItems}
                                                itemsPerPage={limit}
                                                currentPage={currentPage}
                                                onPageChange={(page) => setCurrentPage(page)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <Spinner />
                        )}
                    </div>

                    <Footer />
                </div>
            </>
        </div>
    )
}

export default Center