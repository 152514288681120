import React, { useEffect, useState } from 'react'
import moment from "moment/moment";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Loader from '../include/Loader';
import Menu from '../include/Menu';

import ColDetail from '../general/ColDetail'
import ShowImage from '../general/ShowImage'


import OwlCarousel from "react-owl-carousel"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const PendingDetails = () => {

    const location = useLocation();
    const navigate = useNavigate();
    var data = location?.state;
    console.log("~~~~~~~~~~", data);

    useEffect(() => {
        if (localStorage.getItem("ContigoU_Admin_ID") == null) {
            navigate("/contigo-u-admin");
            return;
        }
        document.getElementById("page-loader").style.display = "none";

        var element = document.getElementById("page-container");
        element.classList.add("show");
    }, []);

    const getRoomTypeLabel = (roomType) => {
        switch (roomType) {
            case 1:
                return 'Private Rooms';
            case 2:
                return 'Double Rooms';
            case 3:
                return 'Triples Rooms';
            case 4:
                return 'Quads Rooms'
            default:
                return 'Unknown Room Type';
        }
    };



    const roomTypeData = data?.room_type;
    const roomTypeLabel = getRoomTypeLabel(roomTypeData);

    const getSelectionLabel = (value) => (value ? 'Selected' : 'Unselected');

    const medicareData = data?.medicare;
    const medicareLabel = getSelectionLabel(medicareData);

    const medicadeData = data?.medicade;
    const medicadeLabel = getSelectionLabel(medicadeData);

    // const ShowImage = ({ title, data, alt, dummyUrl }) => {
    //     return (
    //       <div>
    //         <h3>{title}</h3>
    //         {data && Array.isArray(data) ? (
    //           data.map((imageUrl, index) => (
    //             <img
    //               key={index}
    //               src={imageUrl}
    //               alt={alt}
    //               onError={(e) => {
    //                 e.target.onerror = null;
    //                 e.target.src = dummyUrl;
    //               }}
    //             />
    //           ))
    //         ) : (
    //           <img src={dummyUrl} alt={alt} />
    //         )}
    //       </div>
    //     );
    //   };

    //   // Inside your component where you render the <ShowImage> component
    //   const imageUrls = data?.image;

    // file

    const imageMappings = {
        pdf: './assets/img/pdf-icon.png',
        xlsx: './assets/img/xlsx.png',
        doc: './assets/img/docs.png',
        docx: './assets/img/docs.png',
    };

    const getFileExtension = (filename) => {
        const ext = filename?.split('.').pop();
        return ext ? ext.toLowerCase() : '';
    };

    const fileExtension = getFileExtension(data?.certificate);
    const imageSource = imageMappings[fileExtension] || './assets/img/file.png';

    const imageUrl = data?.certificate;

    let imageName = null;
    if (imageUrl) {
        const urlParts = imageUrl.split('/');
        const fileNameWithExtension = urlParts[urlParts.length - 1];
        const fileNameParts = fileNameWithExtension.split('.');
        imageName = fileNameParts[0];
    }

    const openFile = () => {
        console.log("click")
        if (data?.certificate) {
            if (fileExtension === 'pdf') {
                window.open(data?.certificate, '_blank'); // Open PDF in a new tab
            } else if (['doc', 'docx', 'xlsx'].includes(fileExtension)) {
                // For DOC, DOCX, and XLSX files, download the file using the 'download' attribute
                const link = document.createElement('a');
                link.href = data.certificate;
                link.target = '_blank';
                link.download = `file.${fileExtension}`;
                link.click();
            } else {
                window.open(data.certificate, '_blank');
            }
        }
    };

    const getStatusElement = (status) => {
        let el;
        switch (status) {
            // case 0:
            //     el = <span className="text-info">Unverified</span>;
            //     break;
            case 1:
                el = <span className="badge badge-pill badge-warning">Pending</span>;
                break;
            case 2:
                el = <span className="badge badge-pill badge-success">Approved</span>;
                break;
            case 3:
                el = <span className="badge badge-pill badge-info">Blocked</span>;
                break;
            case 5:
                el = <span className="badge badge-pill badge-danger">Rejected</span>;
                break; // This break statement is missing in your original code
            default:
                el = <span></span>;
                break;
        }
        return el;
    };


    const daysOfWeek = {
        "Monday": data?.opening_days?.mon === true ? "Open" : "Closed",
        "Tuesday": data?.opening_days?.tue === true ? "Open" : "Closed",
        "Wednesday": data?.opening_days?.wed === true ? "Open" : "Closed",
        "Thursday": data?.opening_days?.thu === true ? "Open" : "Closed",
        "Friday": data?.opening_days?.fri === true ? "Open" : "Closed",
        "Saturday": data?.opening_days?.sat === true ? "Open" : "Closed",
        "Sunday": data?.opening_days?.sun === true ? "Open" : "Closed"
    };

    return (
        <>
            <Loader />
            <div
                id="page-container"
                className="fade page-sidebar-fixed page-header-fixed"
            >
                <Menu />
                <div id="content" className="content">
                    <ol className="breadcrumb float-xl-right">
                        <li className="breadcrumb-item basePath ">
                            {/* <a href="javascript:;">User List</a> */}
                            <NavLink to={"/center-management"}>Center List</NavLink>
                        </li>
                        <li className="breadcrumb-item active currentPath">
                            Center Details
                        </li>
                    </ol>
                    <h1 className="page-header">
                        <i
                            className="fa fa-arrow-left edit"
                            onClick={() => navigate("/center-management", { state: { active: location?.state?.active } })}
                            style={{
                                cursor: "pointer",
                                fontSize: "20px",
                                marginTop: "7px",
                                marginRight: "10px",
                            }}
                        ></i>
                        Center Details
                    </h1>

                    {/* <div className="btn btn-success mt-2 px-3">Verify</div> */}
                    <div
                        style={{
                            backgroundColor: "white",
                            padding: "20px",
                            borderRadius: "20px",
                            marginTop: "10px",
                        }}
                    >
                        <div className="row RestName p-5" style={{ borderRadius: "20px" }}>
                            <div className="mx-auto ">
                                <span style={{ fontSize: "18px", fontWeight: "700" }}>
                                    Details of {data?.first_name} {data?.last_name}
                                </span>
                                {/* <i className="fa fa-edit ml-2" onClick={()=>{navigate("/edit-guru-details", {state: data,})}}></i> */}
                            </div>
                        </div>
                        <br />

                        <div>
                            <div className="row">

                                <ColDetail title="Center Name" data={data?.center_name} />
                                <ColDetail title="Total Beds" data={data?.No_of_bed} />
                                {/* <ColDetail title="Male Beds" data={data?.male_beds} /> */}
                                {/* <ColDetail title="Female Beds" data={data?.female_beds} /> */}
                                <ColDetail title="Status" data={getStatusElement(data?.status)} />

                                <ColDetail title="Description" data={data?.description} />
                                <ColDetail title='Room Type' data={roomTypeLabel} />




                                {/* <ColDetail title="Joining Date" data={moment(data?.createdAt).format("YYYY-MM-DD hh:mm:ss A")} />
                                <ColDetail title="Date of birth" data={moment(data?.dob).format("YYYY-MM-DD")} /> */}
                                {/* <ColDetail title="Working Hours" data={showWorkingHours(data?.working_hours).replace(/;/g, '\n')} /> */}
                            </div>

                            <div className='mt-4'>
                                <h3>Timing</h3>
                                <div className='row'>
                                    <ColDetail title='Opening Time' data={data?.timing} />
                                    <ColDetail title='Closing Time' data={data?.end_timing} />
                                </div>
                            </div>

                            <div className='mt-4'>
                                <h3>Opening Days</h3>
                                <div className='row'>
                                    <div>
                                        <ul>
                                            {Object.entries(daysOfWeek).map(([day, status]) => (
                                                <li className='mx-2 mt-2' key={day}>{day}: {status}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className='mt-4'>
                                <div className='mb-2'>
                                    <h3>Supported Insurance</h3>
                                </div>
                                <div className='row'>
                                    {/* <ColDetail title='Medicare' data={medicareLabel} />
                                    <ColDetail title='Medicade' data={medicadeLabel} /> */}
                                    {medicareData && medicadeData ? (
                                        <>
                                            <ColDetail title='Medicare' data={medicareLabel} />
                                            <ColDetail title='Medicade' data={medicadeLabel} />
                                        </>
                                    ) : medicareData ? (
                                        <ColDetail title='Medicare' data={medicareLabel} />
                                    ) : medicadeData ? (
                                        <ColDetail title='Medicade' data={medicadeLabel} />
                                    ) : null}

                                    <div className='col'>
                                        <h4
                                            style={{ fontSize: "16px", fontWeight: "600", color: "#00657B" }}>
                                            Certificate <span style={{ color: "black", fontSize: "10px", }}>(Download Here)</span></h4>
                                        <img
                                            src={imageSource}
                                            alt="certificate"
                                            title="Center Certificate"
                                            onClick={openFile}
                                            style={{ cursor: 'pointer', width: "auto", height: "40px" }}
                                        />
                                        <p>
                                            {imageName && (
                                                <p className='mt-1' style={{ fontSize: "12px" }}>{imageName}</p>
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className='mt-4'>
                                <h3>Center Address</h3>
                                <div className='row'>
                                    <ColDetail title='Address' data={data?.address?.address} />
                                    <ColDetail title='City' data={data?.address?.city} />
                                    <ColDetail title='State' data={data?.address?.state} />
                                    <ColDetail title='Country' data={data?.address?.country} />
                                    <ColDetail title='Zip Code' data={data?.address?.zip} />

                                </div>
                            </div>

                            <div>
                                <h3>Center Images</h3>
                                <div className='row'>
                                    <div className='col mt-2'>
                                        <div style={{ width: 'auto', height: '250px', margin: '0 auto', borderRadius: "9px" }}>
                                            <OwlCarousel
                                                className="owl-dots"
                                                loop
                                                margin={10}
                                                autoplay
                                                animateIn="fadeInRight"
                                                animateOut="fadeOutLeft"
                                                autoplayTimeout={3000}
                                                nav={false}
                                                items={1} // Set items prop to 1 to show only one image at a time
                                            >
                                                {data?.center_image.map((elem) =>
                                                    <img src={elem} style={{ width: "auto", height: "250px", cursor: "pointer" }} />

                                                )}

                                                {/* // <img src={data?.center_image} style={{ width: 'auto', height: '100px'}} /> */}

                                            </OwlCarousel>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>



                    </div>
                </div>
            </div>
        </>
    )
}

export default PendingDetails