import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Footer from './include/Footer';
import Loader from './include/Loader';
import Menu from './include/Menu'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { toast } from "react-toastify";
import axios from 'axios';
import { CONTIGO_API_URL } from '../BaseURL';
const PrivacyPolicy = () => {

    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem("ContigoU_Admin_ID") == null) {
            navigate("/contigo-u-admin");
            return;
        }
        document.getElementById("page-loader").style.display = "none";
        var element = document.getElementById("page-container");
        element.classList.add("show");
        getCMS();
    }, []);
    const [privacy, setPrivacy] = useState('');

    const getCMS = () => {
        const myurl = `${CONTIGO_API_URL}api/admin/get-cms`;

        const bodyFormData = new URLSearchParams();
        bodyFormData.append('auth_code', "Contigo-U$123");

        axios({
            method: "post",
            url: myurl,
            data: bodyFormData,
            headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + localStorage.getItem('ContigoU_Admin_ID') },
        })
            .then(async (response) => {
                console?.log("@@", response?.data.data);
                if (response?.data?.status) {
                    setPrivacy(response?.data?.data?.privacy_policy)
                } else {
                    toast.error("Something went wrong");
                }
            })
            .catch((error) => {
                toast.error("Something went wrong");
            });
    };
    const handleUpdate = () => {
        const myurl = `${CONTIGO_API_URL}api/admin/update-cms`;

        const bodyFormData = new URLSearchParams();
        bodyFormData.append('auth_code', "Contigo-U$123");
        bodyFormData.append('privacy_policy', privacy);

        axios({
            method: "post",
            url: myurl,
            data: bodyFormData,
            headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + localStorage.getItem('ContigoU_Admin_ID') },
        })
            .then(async (response) => {
                console?.log("@@", response?.data.data);
                if (response?.data?.status) {
                    toast.success('Privacy Policy updated')
                } else {
                    toast.error("Something went wrong");
                }
            })
            .catch((error) => {
                toast.error("Something went wrong");
            });
    }

    return (
        <>
            <Loader />

            <div
                id="page-container"
                className="fade page-sidebar-fixed page-header-fixed"
            >
                <Menu />

                <div id="content" className="content">
                    <ol className="breadcrumb float-xl-right">
                        <li className="breadcrumb-item basePath ">
                            <a href="javascript:;">Home</a>
                        </li>
                        <li className="breadcrumb-item active currentPath">
                            Privacy Policy
                        </li>
                    </ol>
                    <h1 className="page-header">Privacy Policy</h1>
                    <div className='container'>
                        <CKEditor id="editor" editor={ClassicEditor} onChange={(e, editor) => { setPrivacy(editor.getData()) }} data={privacy} />
                        <button type='button' className='btn btn-success' style={{ marginTop: "30px", width: "100px" }} onClick={handleUpdate} >Update</button>
                    </div>
                </div >
                <Footer />
            </div >
        </>
    )
}

export default PrivacyPolicy