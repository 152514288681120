import React, { useState, useEffect, useMemo } from 'react'
import { Dropdown, Table } from 'react-bootstrap';
import Footer from '../include/Footer';
import Spinner from '../include/Spinner';
import Loader from "../include/Loader";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { TableHeader, Pagination, Search } from "../Table";
import { toast } from "react-toastify";
import Menu from '../include/Menu';

import axios from 'axios';
import Swal from "sweetalert2";
import { CONTIGO_API_URL } from '../../BaseURL';
import moment from 'moment/moment';


const User = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const [entity, setEntity] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [limit, setlimit] = useState(10);
    const [disable, setDisable] = useState(true);
    const [finalArray, setFinalArray] = useState([]);

    useEffect(() => {
        if (localStorage.getItem("ContigoU_Admin_ID") == null) {
            navigate("/contigo-u-admin");
            return;
        }
        document.getElementById("page-loader").style.display = "none";
        var element = document.getElementById("page-container");
        element.classList.add("show");
        if (disable == true) {
            setEntity([]);
            getPatient()
        }
    }, []);

    const commentsData = useMemo(() => {
        let computedComments = entity;

        if (search) {
            computedComments = computedComments.filter(
                (customers) =>

                    customers.first_name?.toLowerCase()?.includes(search?.toLowerCase()) ||
                    customers.last_name?.toLowerCase()?.includes(search?.toLowerCase()) ||
                    customers.email?.toLowerCase()?.includes(search?.toLowerCase()) ||
                    customers?.phone_number
                        ?.toString()
                        ?.includes(search?.toLowerCase()) ||
                    customers?.country_code
                        ?.toString()
                        ?.includes(search?.toLowerCase()) ||
                    customers.about?.toLowerCase()?.includes(search?.toLowerCase()) ||
                    moment(customers?.createdAt)
                        .format('MM/DD/YYYY hh:mm:ss A')
                        ?.includes(search?.toLowerCase())

            );
        }
        setTotalItems(computedComments.length);

        //Sorting comments
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort(
                (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        //Current Page slice
        return computedComments?.slice(
            (currentPage - 1) * limit,
            (currentPage - 1) * limit + limit
        );
    }, [currentPage, search, sorting, limit, entity]);

    const getPatient = () => {
        const myurl = `${CONTIGO_API_URL}api/admin/all-patients`;
        const fd = new FormData();

        fd.append("page", currentPage);
        axios({
            method: "get",
            url: myurl,
            data: fd,
            headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Bearer " + localStorage.getItem('ContigoU_Admin_ID') },
        }).then(async (response) => {
            console.log(response?.data)
            if (response.data.status === true) {
                let data = await response?.data?.data?.map((e, i) => {
                    return { ...e, sr_no: i + 1 };
                });
                setEntity(data);
                setDisable(false);
            } else {
                toast.error("Something went wrong");
                setDisable(false);
            }
        }).catch((error) => {
            console.log(error, "error")
            toast.error(error.response.data.message);
            setDisable(false);
        })
    }


    const blockCenter = (id, first, last) => {
        Swal.fire({
            title: "Block",
            text: `Are you sure to want to ${first} ${last}`,
            icon: "warning",
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: "Block",
            showCancelButton: true,
            confirmButtonText: "Block"
        }).then((res) => {
            if (res.isConfirmed) {
                const myurl = `${CONTIGO_API_URL}api/admin/block-patient`;
                var bodyFormData = new URLSearchParams();
                bodyFormData.append("user_id", id);
                axios({
                    method: "post",
                    url: myurl,
                    data: bodyFormData,
                    headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Bearer " + localStorage.getItem('ContigoU_Admin_ID') },
                })
                    .then((response) => {
                        console?.log("@@", response?.data);
                        if (response?.data?.status === true) {
                            toast.success(response?.data?.message);
                            setDisable(true);
                            getPatient()
                        } else {
                            toast.error(response?.data?.message);
                        }
                    })
                    .catch((error) => {
                        toast.error("Something went wrong");
                        console?.log("Errors", error);
                    });
            }
        });
    };


    const unblockCenter = (id, first, last) => {
        Swal.fire({
            title: "Unblock",
            text: `Are you sure to want to ${first} ${last}`,
            icon: "warning",
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: "Unblock",
            showCancelButton: true,
            confirmButtonText: "Unblock"
        }).then((res) => {
            if (res.isConfirmed) {
                const myurl = `${CONTIGO_API_URL}api/admin/block-patient`;
                var bodyFormData = new URLSearchParams();
                bodyFormData.append("user_id", id);
                axios({
                    method: "post",
                    url: myurl,
                    data: bodyFormData,
                    headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Bearer " + localStorage.getItem('ContigoU_Admin_ID') },
                })
                    .then((response) => {
                        console?.log("@@", response?.data);
                        if (response?.data?.status === true) {
                            toast.success(response?.data?.message);
                            setDisable(true);
                            getPatient();
                        } else {
                            toast.error(response?.data?.message);
                        }
                    })
                    .catch((error) => {
                        toast.error("Something went wrong");
                        console?.log("Errors", error);
                    });
            }
        });
    };


    const switchStatus = (status) => {
        let el;
        switch (status) {
            // case 0:
            //     el = <span className="text-info">Unverified</span>;
            //     break;
            case 1:
                el = <span className="badge badge-pill badge-success">Active</span>;
                break;
            case 2:
                el = <span className="badge badge-pill badge-danger">Blocked</span>;
                break;
            default:
                el = <span></span>;
                break;
        }
        return el;
    };

    return (
        <>
            <Loader />

            <div
                id="page-container"
                className="fade page-sidebar-fixed page-header-fixed"
            >
                <Menu />

                <div id="content" className="content">
                    <ol className="breadcrumb float-xl-right">
                        <li className="breadcrumb-item basePath ">
                            <a href="/">Home</a>
                        </li>
                        <li className="breadcrumb-item active currentPath">
                            Patient Management
                        </li>
                    </ol>
                    <h1 className="page-header">Patinet Management</h1>
                    {disable == false ? (
                        <div
                            style={{
                                backgroundColor: "white",
                                padding: "20px",
                                borderRadius: "20px",
                                marginTop: "10px",
                            }}
                        >
                            <div className="row w-100">
                                <div className="mb-3 col-12 text-center">
                                    <div className="row">
                                        <div className="col-xl-3 col-lg-3 col-sm-3 col-12 mb-3">
                                            <div className="ml-0">
                                                <div className="d-flex">
                                                    <h5 className="mt-2 mr-1">Search: </h5>
                                                    <Search
                                                        onSearch={(value) => {
                                                            setSearch(value);
                                                            setCurrentPage(1);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-xl-3 col-lg-3 col-sm-3 col-12 d-flex">
                      <button type="button" className="btn btn-success" >Download</button>
                    </div> */}
                                        <div className="col-xl-3 col-lg-3 col-sm-3 col-12 d-flex justify-content-end mb-3">
                                            <div
                                                style={{
                                                    color: "black",
                                                    fontSize: "12px",
                                                    fontWeight: "300",
                                                    paddingTop: "0px",
                                                    paddingBottom: "0px",
                                                }}
                                                className="align-self-center"
                                            >
                                                <h5 className="mt-2 mr-1">Total Patient : {entity?.length}</h5>
                                                {/* <input type="text" className="form-control" value="number" style:  /> */}
                                            </div>
                                        </div>

                                        <div className="col d-flex justify-content-end mb-3">

                                            <div
                                                style={{
                                                    color: "black",
                                                    fontSize: "12px",
                                                    fontWeight: "300",
                                                    paddingTop: "0px",
                                                    paddingBottom: "0px",
                                                }}
                                                className="align-self-center"
                                            >
                                                <b>Rows per page :&nbsp;</b>
                                            </div>
                                            <div className="align-self-center">
                                                <Dropdown>
                                                    <Dropdown.Toggle
                                                        variant="none"
                                                        id="dropdown-basic"
                                                        style={{
                                                            cursor: "auto",
                                                            backgroundColor: "white",
                                                            borderColor: "#d5dbe0",
                                                            paddingBottom: "3px",
                                                            paddingTop: "3px",
                                                        }}
                                                    >
                                                        {limit}&nbsp;<i className="fa fa-caret-down"></i>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {limit !== 10 ? (
                                                            <>
                                                                <Dropdown.Item
                                                                    onClick={() => {
                                                                        setlimit(10);
                                                                    }}
                                                                >
                                                                    10
                                                                </Dropdown.Item>
                                                            </>
                                                        ) : null}

                                                        {limit !== 20 ? (
                                                            <>
                                                                <Dropdown.Item
                                                                    onClick={() => {
                                                                        setlimit(20);
                                                                    }}
                                                                >
                                                                    20
                                                                </Dropdown.Item>
                                                            </>
                                                        ) : null}

                                                        {limit !== 30 ? (
                                                            <>
                                                                <Dropdown.Item
                                                                    onClick={() => {
                                                                        setlimit(30);
                                                                    }}
                                                                >
                                                                    30
                                                                </Dropdown.Item>
                                                            </>
                                                        ) : null}

                                                        {limit !== 50 ? (
                                                            <>
                                                                <Dropdown.Item
                                                                    onClick={() => {
                                                                        setlimit(50);
                                                                    }}
                                                                >
                                                                    50
                                                                </Dropdown.Item>
                                                            </>
                                                        ) : null}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row ">
                                        <div className="col-12">
                                            <div className="table-responsive">
                                                <Table striped bordered hover>
                                                    <thead>
                                                        {/* <TableHeader
                              headers={userHeader}
                              onSorting={(field, order) =>
                                setSorting({ field, order })
                              }
                            /> */}
                                                        <tr>
                                                            <th>Sr No.</th>
                                                            <th>Name</th>
                                                            <th>Email</th>
                                                            <th>Phone No.</th>
                                                            <th>About Us</th>
                                                            <th>Joining Date</th>
                                                            <th>Status</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {commentsData?.map((e, i) => (
                                                            <tr key={e?.id}>
                                                                <>
                                                                    <td>{search ? i + 1 : e?.sr_no}</td>
                                                                    <td>{e?.first_name} {e?.last_name}</td>
                                                                    <td>{e?.email}</td>
                                                                    <td>+{e?.country_code}-{e?.phone_number}</td>
                                                                    <td>{e?.about}</td>
                                                                    <td>{moment(e?.createdAt).format("MM/DD/YYYY hh:mm:ss A")}</td>
                                                                    <td>{switchStatus(e?.status)}</td>
                                                                    <td>
                                                                        {e?.status === 1 ? (
                                                                            <span className="text text-info icon-wrapper" data-tooltip="Block" style={{ cursor: "pointer" }} onClick={() => blockCenter(e.id, e.first_name, e.last_name)}>
                                                                                <u>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="27" height="27" viewBox="0 0 48 48">
                                                                                        <path fill="#424242" d="M24,4c-5.5,0-10,4.5-10,10v4h4v-4c0-3.3,2.7-6,6-6s6,2.7,6,6v4h4v-4C34,8.5,29.5,4,24,4z"></path><path fill="#FB8C00" d="M36,44H12c-2.2,0-4-1.8-4-4V22c0-2.2,1.8-4,4-4h24c2.2,0,4,1.8,4,4v18C40,42.2,38.2,44,36,44z"></path><path fill="#C76E00" d="M24 28A3 3 0 1 0 24 34A3 3 0 1 0 24 28Z"></path>
                                                                                    </svg>
                                                                                </u>
                                                                            </span>
                                                                        ) :
                                                                            (
                                                                                <span className="text text-success icon-wrapper" data-tooltip="Unblock" style={{ cursor: "pointer" }} onClick={() => unblockCenter(e.id, e.first_name, e.last_name)}>
                                                                                    <u>
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="27" height="27" viewBox="0 0 48 48">
                                                                                            <path fill="#424242" d="M24,4c-5.5,0-10,4.5-10,10v4h4v-4c0-3.3,2.7-6,6-6s6,2.7,6,6h4C34,8.5,29.5,4,24,4z"></path><path fill="#FB8C00" d="M36,44H12c-2.2,0-4-1.8-4-4V22c0-2.2,1.8-4,4-4h24c2.2,0,4,1.8,4,4v18C40,42.2,38.2,44,36,44z"></path><path fill="#C76E00" d="M24 28A3 3 0 1 0 24 34A3 3 0 1 0 24 28Z"></path>
                                                                                        </svg>
                                                                                    </u>
                                                                                </span>
                                                                            )
                                                                        }


                                                                    </td>
                                                                </>
                                                            </tr>
                                                        ))}


                                                    </tbody>
                                                </Table>
                                                {commentsData.length === 0 ? (
                                                    <div className="row d-flex justify-content-center w-100">
                                                        <div className="mx-auto  d-flex justify-content-center w-100">
                                                            <img
                                                                src="./assets/img/icon/no-location.png"
                                                                className="form-img__img-preview"
                                                                style={{ width: "100px", height: "100px" }}
                                                                alt=""
                                                            />
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="mt-2 d-flex justify-content-sm-center justify-content-xs-center justify-content-lg-end"
                                        style={{
                                            overflowX: "auto",
                                        }}
                                    >
                                        <Pagination
                                            total={totalItems}
                                            itemsPerPage={limit}
                                            currentPage={currentPage}
                                            onPageChange={(page) => setCurrentPage(page)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <Spinner />
                    )}
                </div>

                <Footer />
            </div>
        </>
    )
}

export default User