import React from 'react';

function Footer() {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <>
            {/* Your other footer content */}
            <a
                href="#"
                onClick={scrollToTop}
                className="btn btn-icon btn-circle btn-success btn-scroll-to-top fade"
                data-click="scroll-top"
            >
                <i className="fa fa-angle-up"></i>
            </a>
        </>
    );
}

export default Footer;
