import React, { useState, useEffect } from "react";
import { NavLink, Link, useHistory, useNavigate } from "react-router-dom";
// import env from "react-dotenv";

import { toast } from "react-toastify";
import axios from "axios";
import { CONTIGO_API_URL } from "../../BaseURL";


function Login() {

  const navigate = useNavigate();

  const [loginInfo, setLoginInfo] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [disable, setDisable] = useState(false);

  const InputEvent = (e) => {
    const newLoginInfo = { ...loginInfo };
    newLoginInfo[e.target.name] = e.target.value?.trimStart();
    if (e.target.name === "email") {
      newLoginInfo[e.target.name] = e.target.value.toLowerCase()?.trimStart();
    } else {
      newLoginInfo[e.target.name] = e.target.value?.trimStart();
    }
    setLoginInfo(newLoginInfo);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (validate()) {
      setDisable(true);
      const { email, password } = loginInfo;

      const myurl = `${CONTIGO_API_URL}api/admin/login`;
      var bodyFormData = new URLSearchParams();
      bodyFormData.append("email", email);
      bodyFormData.append("password", password);
      bodyFormData.append("auth_code", "Contigo-U$123")
      axios({
        method: "post",
        url: myurl,
        data: bodyFormData,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
        .then((response) => {
          console.log('@@', response?.data);
          if (response?.data?.status === true) {
            localStorage.setItem("ContigoU_Admin_ID", response?.data?.data?.token);
            // axios({
            //   method: "post",
            //   //   url: D_URL+"/get-admin-data",
            //   data: [],
            //   headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + response?.data?.data[0].token },
            // }).then((response) => {

            //   let img = response?.data?.data?.image;
            //   let img1 = "./assets/img/icon/profileIcon.png";

            localStorage.setItem("ContigoU_Admin_NAME", response.data.data.name);
            localStorage.setItem("ContigoU_Admin_EMAIL", response.data.data.email);
            localStorage.setItem(
              "ContigoU_Admin_IMAGE",
              response?.data?.data?.image
            );
            navigate('/dashboard');
            // })

          } else {
            setDisable(false);
            toast.error("!... Your email or password is wrong");
          }
        })
        .catch((error) => {
          console.log("Errors", error);
          toast.error("!...Something went wrong");
          setDisable(false);
        });
    } else {
      setDisable(false);
    }
  };
  const validate = () => {
    let input = loginInfo;

    let errors = {};
    let isValid = true;

    if (!input["email"]) {
      isValid = false;
      errors["email_err"] = "Please enter email";
    }
    if (!input["password"]) {
      isValid = false;
      errors["password_err"] = "Please enter password";
    }
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (input["email"] && !regex.test(input["email"])) {
      errors["email_err"] = "Please enter valid email address";
    }


    setErrors(errors);
    return isValid;
  };

  useEffect(() => {
    if (localStorage.getItem("ContigoU_Admin_ID") != null) {
      toast.success(
        `Already login as ${localStorage.getItem("ContigoU_Admin_NAME")}`
      );
      navigate("/dashboard");
    }
    document.getElementById("page-loader").style.display = "none";

    var element = document.getElementById("page-container");
    element.classList.add("show");
  }, []);

  return (
    <>
      <div id="page-loader" className="fade show">
        <span className="spinner"></span>
      </div>

      {/* <div className="login-cover">
        <div
          className="login-cover-image"
          style={{
            backgroundImage: "url(assets/img/login-bg/login-bg-17.jpg)",
          }}
          data-id="login-cover-image"
        ></div>
        <div className="login-cover-bg"></div>
      </div> */}

      <div id="page-container" className="fade">
        <div
          className="login login-v2"
          data-pageload-addclassName="animated fadeIn"
        >
          <div className="login-header">
            <div className="brand">
              <img src="/assets/img/contigo.svg" style={{ height: "80PX", width: "auto", objectFit: "cover" }} />

              {/* <b>ContigoU</b> */}

              {/* <small>Login for ContigoU admin panel</small> */}
            </div>
            <div className="icon">
              <i className="fa fa-lock"></i>
            </div>
          </div>

          <div className="login-content">
            <form onSubmit={(e) => submitHandler(e)}>
              <div className="form-group m-b-20">
                <input
                  type="text"
                  className="form-control form-control-lg ml-0"
                  placeholder="Email Address"
                  name="email"
                  value={loginInfo?.email}
                  onChange={InputEvent}
                />
                <div className="text-danger">{errors.email_err}</div>
              </div>
              <div className="form-group m-b-20">
                <input
                  value={loginInfo?.password}
                  type="password"
                  className="form-control form-control-lg ml-0"
                  placeholder="Password"
                  name="password"
                  onChange={InputEvent}
                />
                <div className="text-danger">{errors.password_err}</div>
              </div>
              <div className="form-group m-b-20">
                <Link
                  to={{
                    pathname: "/forgot-password",
                  }}
                  state={loginInfo.email}
                >
                  Forgot Password?
                </Link>
              </div>
              <div className="login-buttons">
                <button
                  type="submit"
                  class="btn btn-success btn-block btn-lg"
                  disabled={disable}
                >
                  {disable ? "Processing..." : "Sign me in"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
