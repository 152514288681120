import React, { useState, useEffect } from 'react'
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import ColDetail from '../general/ColDetail'
import ShowImage from '../general/ShowImage'
import Loader from '../include/Loader';
import Menu from '../include/Menu';
import moment from "moment/moment";



const CenterDetails = () => {

    const location = useLocation();
    const navigate = useNavigate();
    var data = location.state;
    console.log("~~~~~~~~~~", data);

    useEffect(() => {
        if (localStorage.getItem("ContigoU_Admin_ID") == null) {
            navigate("/contigo-u-admin");
            return;
        }
        document.getElementById("page-loader").style.display = "none";

        var element = document.getElementById("page-container");
        element.classList.add("show");
    }, []);



    return (
        <>
            <Loader />
            <div
                id="page-container"
                className="fade page-sidebar-fixed page-header-fixed"
            >
                <Menu />
                <div id="content" className="content">
                    <ol className="breadcrumb float-xl-right">
                        <li className="breadcrumb-item basePath ">
                            {/* <a href="javascript:;">User List</a> */}
                            <NavLink to={"/nurse-list"}>Nurse List</NavLink>
                        </li>
                        <li className="breadcrumb-item active currentPath">
                            Nurse Details
                        </li>
                    </ol>
                    <h1 className="page-header">
                        <i
                            className="fa fa-arrow-left edit"
                            onClick={() => navigate("/nurse-list")}
                            style={{
                                cursor: "pointer",
                                fontSize: "20px",
                                marginTop: "7px",
                                marginRight: "10px",
                            }}
                        ></i>
                        Nurse Details
                    </h1>

                    {/* <div className="btn btn-success mt-2 px-3">Verify</div> */}
                    <div
                        style={{
                            backgroundColor: "white",
                            padding: "20px",
                            borderRadius: "20px",
                            marginTop: "10px",
                        }}
                    >
                        <div className="row RestName p-5" style={{ borderRadius: "20px" }}>
                            <div className="mx-auto ">
                                <span style={{ fontSize: "18px", fontWeight: "700" }}>
                                    Details of {data?.first_name} {data?.last_name}
                                </span>
                                {/* <i className="fa fa-edit ml-2" onClick={()=>{navigate("/edit-guru-details", {state: data,})}}></i> */}
                            </div>
                        </div>
                        <br />

                        <div className="row">
                            <ShowImage
                                title="License Image"
                                data={data?.license}
                                url={data.license ? data?.license : './assets/img/icon/customer.png'}
                                alt="DriverImage"
                                dummyUrl={"/assets/img/icon/customer.png"}
                            />
                        </div>
                        <br />

                        <div>
                            <div className="row">

                                <ColDetail title="Center Name" data={data?.center_name} />
                                <ColDetail title="Email" data={data?.email} />
                                <ColDetail title="Business Email" data={data?.business_email} />
                                <ColDetail title="Mobile Number" data={`${data?.country_code}-${data?.phone_number}`} />


                                <ColDetail title="Total Beds" data={data?.No_of_bed} />
                                {/* <ColDetail title="Status" data={data?.status} /> */}
                                {/* <ColDetail title="Joining Date" data={moment(data?.createdAt).format("YYYY-MM-DD hh:mm:ss A")} /> */}
                                <ColDetail title="Joining Date" data={moment(data?.createdAt).format("MM/DD/YYYY hh:mm:ss A")} />
                                <ColDetail title='About' data={data?.about}/>
                                <ColDetail title='Business Owner' data={data?.business_owner ? 'Yes' : 'No'} />
                                <ColDetail title='Business Id' data={data?.bussiness_id} />
                            </div>

                        </div>

                        <div className='row'>

                            <ColDetail title='State' data={data?.state} />
                            <ColDetail title="City" data={data?.city} />
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}

export default CenterDetails